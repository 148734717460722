@import 'src/styles';

.container {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 25px;
    margin-bottom: 13px;

    &__text {
      font-size: 20px;
      line-height: normal;
      font-weight: 500;
      text-align: left;

      svg {
        fill: $gray-1;
      }

      @include smallTablet {
        padding-top: 3.5px;
        text-align: start;
      }
    }
    &__price {
      font-size: 14px;
      line-height: normal;
      font-weight: 500;
      text-align: left;

      @include smallTablet {
        text-align: start;
      }
    }
    @include smallTablet {
      flex-direction: column;
    }
  }
  .content {
    background: white;
    border-radius: 16px;

    .product {
      display: flex;
      border-bottom: 1px solid $light-2;
      padding: 0 $spacing;
      & > * {
        max-width: 50%;
        width: 50%;
      }
      @include smallTablet {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
        & > * {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}
