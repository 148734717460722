@import 'src/styles';

.dropdown {
  margin-top: 12px;
  &__button {
    color: $gray-4;
    font-size: 13px;
    line-height: 20px;
    border: 1px solid $light-3;
    border-radius: 2px;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 24px;
    min-width: 175px;
    width: 100%;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 4px;
    .dates {
      width: 60%;
      min-height: 200px;
      margin-top: 1px;
      height: 100%;
      .days {
        white-space: nowrap;
        width: 100%;
        font-size: 14px;
        &__label {
          min-width: 25px;
          display: inline-block;
        }
        &__day {
          margin-left: 10px;
        }
      }
    }
    .closing {
      margin-top: 0px;
      margin-left: 4px;
      &__title {
        margin-bottom: 2px;
      }
      &__dates {
        background: $light-5;
        margin-bottom: 4px;
        width: 100%;
        padding: 4px;
        .period {
          display: block;
          white-space: nowrap;
        }
      }
    }
  }
}
