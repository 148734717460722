@import 'src/styles';

.profile {
  margin: 5px 5px 0 25px;

  .title {
    padding-left: 25px;
    text-align: left;
    font-size: 24px;
    font-weight: 800;
    color: $gray-8;
  }

  .subtitle {
    font-size: 24px;
    color: $gray-8;
  }

  .content {
    padding: 16px 27px 29px 25px;
    background: $white;
    @mixin form {
      .form {
        &__input {
          .input {
            height: 40px;
            border-radius: 4px;
          }
        }
      }
    }
    &__main {
      padding-right: 24px;

      @include form;
      .buttonReplacer {
        height: 40px;
      }

      .passwordBtnContainer {
        text-align: center;

        .passwordFormBtn {
          background-color: transparent;
          border: 1px solid $light-3;
          border-radius: 25px;
          height: 40px;
          color: $gray-3;
          font-size: 14px;
          width: 75%;
        }
      }
    }
    &__password {
      margin-left: auto;
      max-width: fit-content;
    }
  }
}
.tcTags {
  display: flex;
  justify-content: center;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &__icon {
    font-size: 30px;
    color: $primary;
    margin-bottom: 15px;
  }
  &__text {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $gray-4;
    white-space: pre-wrap;
    text-align: center;
  }
}
