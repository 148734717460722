@import 'src/styles';

.submit {
  position: fixed;
  right: 30px;
  margin-bottom: 80px;
  bottom: 24px;

  .button {
    box-shadow: 0 1px 20px 10px #ffffffc4;
    height: 50px;
    font-size: 15px;
    letter-spacing: 2.16px;
    text-transform: uppercase;
  }
}
