@import 'src/styles';

.wrapper {
  width: var(--navbar-width);
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: $dark-3 0% 0% no-repeat padding-box;
  border-right: none;
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

  :global(.ant-menu) {
    .wrapperCollapsed & {
      width: 100% !important;
    }
  }

  .collapseButtonWrapper {
    display: flex;
    justify-content: flex-start;

    .wrapperCollapsed & {
      justify-content: center;
    }
    .collapseButton {
      color: white;
      font-size: 1.375rem;
      width: 54px;
      border: 1px solid transparent;
      &:hover {
        background: rgba(0, 0, 0, 0.018);
        color: var(--primary);
      }
    }
  }

  .wrapperCollapsed & {
    width: var(--navbar-collapsed-width);
  }

  .title {
    display: flex;
    align-content: center;
    border-bottom: 2px solid $third;
    padding: 25px 15px;
    margin-bottom: 65px;
    grid-column-gap: 0.625rem;
    min-height: 100px;

    .wrapperCollapsed & {
      justify-content: center;
    }

    .userIcon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      svg {
        height: 19px;
        width: 20px;
        fill: $white;
      }
    }

    .account {
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-row-gap: 0.3125rem;
      opacity: 1;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;

      &__firstName {
        color: $white;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
      }
      &__lastName {
        color: $white;
        font-size: 16px;
        margin: 0;
      }

      .wrapperCollapsed & {
        display: none;
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .menuWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .navMenuLoading {
      i {
        background-color: white;
      }
    }

    .menu {
      background: $dark-3 0% 0% no-repeat padding-box;
      &__item {
        color: $white;
        background: $dark-4 !important;
        &:focus,
        &:hover {
          color: $light-1;
        }

        &_selected {
          color: $white;
          background: $dark-5 !important;
          border-right: 4px solid $third;
          &:after {
            border: 0px;
          }
        }
      }
    }
  }
}

.menuContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footer {
  background-color: $secondary;
  height: 55px;
  display: flex;
  align-items: center;
  opacity: 1;
  min-width: var(--navbar-width);

  .wrapperCollapsed & {
    opacity: 0;
    display: none;
  }

  .info {
    color: $white;
    font-size: 15px;
    font-weight: 600;
    display: block;
    margin: 0 auto;

    @include isTheme($theme-perret) {
      font-size: 13px;
      display: inline-block;
    }
  }
}

.logoutWrapper {
  margin-bottom: 55px;
  display: flex;
  flex-direction: column;
  gap: $spacing;

  .logout {
    position: relative;
    padding: 0px 25px;
    text-align: center;

    .wrapperCollapsed & {
      padding-left: 0;
      padding-right: 0;
    }

    &_button {
      overflow: hidden;
      background: transparent;
      border-radius: 12px;
      border: 1px solid $third;
      color: $white;
      transition: width 0.3s;

      &:focus,
      &:hover {
        background: transparent;
      }

      &__text {
        opacity: 1;
        transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
      }

      .wrapperCollapsed & {
        width: 36px;
        padding-left: 11px;
        padding-right: 11px;

        .logout_button__text {
          display: none;
          pointer-events: none;
        }
      }
    }
  }
}
