@import 'src/styles';

.wrapper {
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
  color: $gray-3;
}

.memberRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 16px;
}

.address {
  margin: 0;
}

.leaderIcon {
  width: 20px;
  height: 20px;
  color: $orange;
  margin-right: 5px;
}

.overlay {
  min-width: 50%;
  max-width: 700px;
}

.reducePadding {
  padding-top: 0;
}

.marginBottom {
  margin-bottom: 10px;
}

.memberInfo {
  max-width: 500px;
  margin-right: 16px;
}
