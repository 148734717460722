@import 'src/styles';

.container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 3px;
  .checkbox {
    padding: 4px 2px;
    width: 100%;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    border-top: 1px dashed $gray-5;
    border-bottom: 1px dashed $gray-5;
    transition: 0.3s all;
    span {
      color: $gray-3;
      padding: 0px;
    }

    span:first-child,
    &:checked {
      & span {
        background-color: $white;
        border-color: $blue-3 !important;
        &::after {
          border-color: $blue-3 !important;
        }
      }
    }

    &:after {
      display: none;
    }
    &:hover {
      transition: 0.3s all;
      box-shadow: 2px 1px 4px $gray-5;
    }
  }

  &_light {
    .checkbox {
      span {
        color: white;
      }
    }
  }
}
