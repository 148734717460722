@import 'src/styles';

.flags {
  line-height: 16px;
  display: inline-block;
  vertical-align: text-top;
}

.conditioning {
  display: flex;
  justify-content: left;
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 6px;
  text-align: center;
  font-weight: 300;
  color: #000000;
  font-size: 12px;
  background-color: $light-3;
}

.productInfo {
  display: inline-block;
  color: $gray-3;
}

.esProduct {
  padding-left: 20px;
  .esPadding {
    margin: 0 10px;
    scale: 1.4;
  }

  .information {
    &__id {
      font-size: 12px;
      margin-right: 10px;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;

  .information {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 3px;

    &__id {
      font-size: 12px;
      margin-right: 10px;
    }
  }
}

.buttonLink {
  @include buttonLink;
  color: $gray-3;
  height: auto;
  white-space: normal;
  text-align: left;
  margin: 0 5px;
}

.emptyInfoBox,
.infoBox {
  display: inline-block;
  width: 20px;
  margin-left: -20px;
}

.infoBox {
  .infoIcon {
    font-size: 16px;
    color: $third;
  }
}

.popover {
  :global(.ant-popover-arrow-content::before) {
    background: #2e2e30eb;
  }

  :global(.ant-popover-inner) {
    border-radius: $spacing / 2;
    background-color: #2e2e30eb;
    color: white;

    :global(.ant-popover-title) {
      border-bottom: solid 1px #545458;
      color: #01bb99;
      font-weight: bold;
    }
  }

  .commentRow {
    max-width: 800px;
    display: flex;
    margin-bottom: 2px;
    color: white;

    .subtitle {
      min-width: 60px;
      display: inline-block;
      font-weight: normal;
    }
  }
}
