@import 'src/styles';

.row {
  --row-padding-x: 20px;
  --row-padding-y: 15px;

  display: grid;
  grid-template-columns: minmax(135px, 0.75fr) minmax(165px, 1.5fr) minmax(195px, 1.25fr) minmax(190px, 1fr) 40px;
  grid-template-areas: 'customer address userInfo certificates checkmark';
  column-gap: 20px;

  margin-bottom: 10px;

  background: $white 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding-left: var(--row-padding-x);
  padding-right: var(--row-padding-x);
  border: 1px solid transparent;
  cursor: pointer;

  &__simpleCustomer {
    opacity: 0.7;
    cursor: default;
    grid-template-columns: 300px minmax(165px, 1fr) minmax(195px, 1fr) 190px 40px;
    grid-template-areas: 'customer address userInfo certificates checkmark';
  }

  &__detailedCustomer {
    &:hover {
      border-color: #dfdfdf;
      background-color: #fdfdfd;
    }
  }

  &__disabled {
    pointer-events: none;

    .column__checkmark {
      .button {
        background: var(--light-2) !important;
      }
    }
  }

  @include tablet {
    grid-template-columns: minmax(135px, 1fr) minmax(190px, 1fr) 40px;
    grid-template-areas: 'customer certificates checkmark';
  }

  &:hover {
    .column__checkmark {
      .button {
        background: var(--primary-gradient-bg-color);

        @include isTheme($theme-perret) {
          background: var(--primary-gradient-bg-color);
        }
        .icon {
          color: var(--customer-card-select-button-icon-color-selected);
        }
      }
    }
  }

  .column {
    word-break: break-word;
    padding: var(--row-padding-y) 0;
    display: flex;
    flex-direction: column;

    &__customer {
      grid-area: customer;
    }

    &__address {
      grid-area: address;

      @include tablet {
        display: none;
      }
    }

    &__userInfo {
      grid-area: userInfo;

      @include tablet {
        display: none;
      }
    }

    &__certificates {
      grid-area: certificates;

      .certificate {
        display: flex;
        flex-direction: column;
      }
    }

    &__checkmark {
      grid-area: checkmark;

      padding: 0;
      align-self: center;

      .button {
        border-radius: 50% !important;
        width: 40px;
        min-width: 40px !important;
        height: 40px;
        min-height: 40px !important;

        @include isTheme($theme-perret) {
          background: transparent;
        }

        .icon {
          color: var(--customer-card-select-button-icon-color);
        }

        &_edit {
          margin-bottom: 15px;
          background: transparent;
          border: 1px solid $light-3;

          .icon {
            color: $gray-4;
          }
        }
      }

      &__simpleCustomer {
        .button {
          opacity: 0.5;
          pointer-events: none;
          background-color: #f0f2f5;
          border: 1px solid red;
          .icon {
            color: red;
          }
        }
      }
    }

    .name {
      font-weight: bold;
      color: $gray-8;
    }
  }
}

.tagContainer {
  :global(.ant-tooltip-inner),
  :global(.ant-tooltip-arrow-content::before) {
    background: white;
  }
}
