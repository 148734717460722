@import 'src/styles';

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  color: $white;
  min-height: 26px;
  padding: 2px;
  cursor: context-menu;
}

.status0 {
  background-color: $light-2;
  color: $dark;
}

.status1 {
  background-color: $dark-1;
}

.status2 {
  background-color: $orange;
}

.status3 {
  background-color: $lighter-blue;
}

.status4 {
  background-color: $darker-blue;
}

.status5 {
  background-color: $green-1;
}

.status6 {
  background-color: $dark-pink;
}
