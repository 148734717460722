@import 'src/styles';

.button {
  width: 100%;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--button-border-color);

  &:hover,
  &:visited {
    background: var(--primary-gradient-bg-color);
    border: 1px solid var(--button-border-color);
    color: white;
  }
  &:active,
  &:focus {
    background: var(--primary-gradient-bg-color);
    border: 1px solid var(--button-border-color);
    color: white;
  }

  &_primary {
    background: var(--primary-gradient-bg-color);
    border-radius: 20px;
    color: white;
  }

  &_secondary {
    transition: none !important;
    background: transparent linear-gradient(45deg, #121f54 0%, #516599 50%, #516599 100%) 0% 0% no-repeat padding-box;
    border-color: var(--gray-8);
    outline-color: var(--gray-8);
    border-radius: 20px;
    color: white;

    &:active,
    &:focus {
      background: transparent linear-gradient(45deg, #121f54 0%, #516599 50%, #516599 100%) 0% 0% no-repeat padding-box;
      border: var(--gray-8);
    }

    &:hover {
      transition: none !important;
      outline-color: var(--button-border-color);
      border-color: var(--button-border-color);
      background: var(--primary-gradient-bg-color);
    }
  }

  &_dashed {
    border: 1px dashed $light-3;
    border-radius: 20px;
    background: white;
    &:hover,
    &:visited,
    &:active,
    &:focus {
      background: transparent;
      border: 1px dashed $gray-1;
      color: $dark-2;
    }
  }

  &_default {
    background: transparent;
    border: 1px solid $gray-8;
    height: 50px;
    border-radius: 38px;
    opacity: 1;
    width: 310px;
    font-size: 20px;
    line-height: 24px;

    &:hover,
    &:visited,
    &:active,
    &:focus {
      background: transparent;
      border: 1px solid $gray-8;
      color: $dark-2;
    }
  }

  &_large {
    height: 75px;
    border-radius: 38px;
    opacity: 1;
    width: 310px;
    font-size: 20px;
    line-height: 24px;
  }

  &_disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;

    &:hover,
    &:visited,
    &:active,
    &:focus {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      cursor: not-allowed;
    }
  }
}
