.responsiveImage {
  width: 100%;
  height: auto;
}

.has-weeknumber {
  .ant-picker-panel {
    padding-left: 15px;
  }
}

.ant-tooltip {
  max-width: 350px;
}
