@import 'src/styles';

.cartRequests {
  margin-left: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  @include mobile {
    margin: 0px 2px;
  }
}
