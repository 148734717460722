@import 'src/styles';

.container {
  border-top: 5px solid $light-5;
  border-bottom: 5px solid $light-5;

  .table {
    th {
      background-color: #ffffff !important;
      color: $gray-2 !important;
      font-weight: 800 !important;
      font-size: 0.75rem;
      text-align: center;
    }
  }
}
