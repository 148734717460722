@import 'src/styles';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .lastPrice {
    display: grid;
    grid-template-columns: 14px 14px 1fr;
    column-gap: $spacing / 2;
  }
}
