@import 'src/styles';

.modal {
  &__img {
    width: 108px;
    height: 129px;
  }
  .container {
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
