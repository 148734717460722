@import 'src/styles';

.infoPoint {
  margin-left: 5px;
}

.cartHeaderRightSide {
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto auto;
  gap: $spacing / 2;
  grid-template-areas:
    'discount actions'
    'article article'
    'qtyEditable qtyEditable'
    'removable removable';
  justify-items: start;

  @media (max-width: 1200px) {
    grid-template-areas:
      'actions actions'
      'discount discount'
      'article article'
      'qtyEditable qtyEditable'
      'removable removable';
  }

  @media (max-width: 973px) {
    grid-template-columns: auto;
    grid-template-areas:
      'actions'
      'discount'
      'qtyEditable'
      'removable'
      'article';
  }

  &__discount {
    grid-area: discount;
  }

  &__article {
    grid-area: article;
  }

  &__qtyEditable {
    grid-area: qtyEditable;
  }
  &__removable {
    grid-area: removable;
  }

  &__actions {
    width: 100%;
    justify-content: flex-end;
    grid-area: actions;

    @media (max-width: 1200px) {
      align-items: center;
    }
  }
}
