@import 'src/styles';

.button {
  border-radius: 50% !important;
  border: 0px solid $white !important;
  height: 40px;
  &:hover {
    border: 1px solid $primary-1 !important;
  }
}

.button {
  span {
    color: $white;
  }
}
