@import 'src/styles';

.wrapper {
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
  color: $gray-3;
}

.reducePadding {
  padding-top: 0;
}

.marginBottom {
  margin-bottom: 10px;
}
