@import 'src/styles';

.wrapper {
  margin-bottom: 40px;

  .title {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 10px;
  }

  .panel {
    padding: 14px;
    background-color: $white;
    border-radius: 5px;
    overflow: hidden;
    border-bottom: 11px solid $primary-1;

    .contentSplit {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: $spacing / 2;
    }
  }
}
