@import 'src/styles';

.address {
  width: 100%;
  .text {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 1px;
    &__title {
      color: $gray-8;
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 3px;
    }
    &__address {
      color: $gray-1;
      font-weight: 600;
      margin-bottom: 3px;
    }
    &__distance {
      color: $gray-1;
      margin-bottom: 3px;
    }
  }
}
