@import 'src/styles';

.container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  &_separator {
    padding-bottom: 20px;
    &:after {
      position: absolute;
      content: '';
      transform: scaleY(0.6);
      left: 0px;
      width: 100%;
      top: 0px;

      @include smallTablet {
        transform: translate(-50%, 0) scaleY(0.6) scaleX(1.4);
        left: 50%;
        width: 100vw;
      }
    }
  }
  .content {
    width: 100%;
  }
  &:last-child {
    padding-bottom: 100px;
  }
  .sidebar {
    margin-top: 64px;
    width: 400px;
    min-width: 400px;
    max-width: 400px;
    position: sticky;
    top: 100px;
  }
  @include tablet {
    flex-direction: column;
    .content {
      padding-right: 24px;
    }
    .sidebar {
      padding-right: 24px;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin-top: 0px;
    }
  }
  @include mobile {
    .content {
      padding: 16px;
    }
    .sidebar {
      padding-right: 0px;
    }
    &:last-child {
      padding: 0px;
    }
  }
}
