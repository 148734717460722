@import 'src/styles';

.margin_price {
  color: #ee5966;
  margin-right: 3px;
  padding-top: 3px;
  white-space: nowrap;
  font-weight: bold;
  display: block;
  margin-bottom: 0px;
  font-size: 10px;

  @include theme($theme-perret) {
    color: #8777d3;
  }
}

.amount {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;

  &.replacement {
    justify-content: center;
  }

  .button {
    align-self: flex-end;
    border-radius: 50% !important;
    border: 1px solid $light-3 !important;
    color: white;
    height: 36px;
    min-height: 36px;
    width: 36px;
    min-width: 36px;
    &[disabled] {
      color: $light-3;
    }
    margin-left: 10px;
  }
  @include tablet {
    padding-right: 16px;
  }
}
