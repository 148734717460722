@import 'src/styles';

.form {
  background: transparent;
  padding-bottom: 1px;

  .radio {
    font-size: 14px;
    line-height: 19px;
    color: $gray-3;
    & span:first-child,
    &:checked {
      & span {
        border-color: $primary;
        &::after {
          background-color: $primary;
        }
      }
    }
    &:hover span:first-child {
      & span {
        border-color: $primary-1;
        &::after {
          background-color: $primary-1;
        }
      }
    }
  }
  &__item {
    margin-bottom: 10px;
    .input {
      height: 40px;
    }
  }
  .button {
    height: 40px;
    width: 100%;
    margin-top: 5px;
  }
}
