@import 'src/styles';

.container {
  background: white;
  padding: 0 0 30px;
  min-width: 700px;
  border-radius: 0 0 16px 16px;

  &__table {
    @include smallTablet {
      padding: 0 4px;
    }

    :global(td.ant-table-row-expand-icon-cell) {
      text-align: right;
    }

    .row {
      & > td {
        background-color: #f5f9ff;
        font-weight: 600;
        border-top: 1px solid #edf4fe;
      }

      &:hover > td {
        background-color: #e9f1ff !important;
      }

      &.emptyRow {
        &:hover > td {
          background-color: #f5f9ff !important;
        }
      }

      &__checked {
        & > td {
          background-color: var(--green-4);
        }

        &:hover > td {
          background-color: var(--green-4) !important;
        }
      }
    }

    .expandableRow > td {
      padding: 0 !important;
    }

    .productSearch {
      display: flex;
      align-items: center;
      gap: $spacing / 2;
    }
  }

  &__child_table {
    :global(.ant-table) {
      margin: 0 !important;
    }
  }

  .expand__button {
    border-radius: 50%;
    border: 1px solid $light-3;
    background: white;
    color: $gray-4;
    width: 30px;
    height: 30px;
    padding: 0;
    &:hover {
      border: 1px solid $primary-1;
    }

    &:disabled {
      border: 1px solid $light-3;
      color: $light-3;
      background: $light-4;
    }
  }
}

th {
  background: $primary !important;
  color: $white !important;
  font-weight: bold !important;
}
tr {
  border: 1px solid #16171b1a !important;
}

th:first-of-type {
  border-radius: 16px 0px 0px 0px !important;
  border-right: none !important;
}

th:last-of-type {
  border-radius: 0px 16px 0px 0px !important;
}

th:before {
  width: 0 !important;
}

.text {
  text-align: start;
}

.title {
  display: block;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.ant-table-cell {
  font-weight: bold;
}

:global(.ant-table-container table > thead > tr:first-child th.ant-table-row-expand-icon-cell:last-child) {
  text-align: right;
}

.comment {
  margin-left: 15px;
  margin-top: 15px;
  display: inline-block;
}
