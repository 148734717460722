@import 'src/styles';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .imageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing;

    .text {
      margin-top: 9px;
      font-size: 38px;
      line-height: 46px;
    }
  }
}
