@import 'src/styles';

.card {
  width: 100%;
  border-radius: 5px;
  background: white;
  border-left: 10px solid $blue-3;
  padding: 20px 29px 25px 25px;
  box-shadow: 0px 2px 4px #d6d6d629;
  margin-bottom: 10px;

  &__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: $gray-8;
    margin-bottom: 4px;
  }
  &__button {
    background: white 0% 0% no-repeat padding-box;
    border: 1px solid $blue-3;
    color: $blue-3;
    border-radius: 20px;
    font-size: 16px;
    line-height: 24px;
    margin-top: 4px;
    height: 40px;
    width: 100%;
    @include mobile {
      font-size: 14px;
    }
  }
}
