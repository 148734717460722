@import 'src/styles';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .subtitle {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 19px;
  }

  .title {
    margin-bottom: 8px;
    color: $dark-2;
    align-self: center;
  }

  .noMarginBottom {
    margin-bottom: 0;
  }

  .responsiveImage {
    margin-bottom: 8px;
  }

  .upload {
    height: 50px;
    align-self: center;

    :global(.ant-spin-container),
    :global(.ant-spin-blur) {
      opacity: 1;
    }
  }

  .button {
    height: 50px;
    font-size: 15px;
    letter-spacing: 2.16px;

    span {
      text-transform: uppercase;
    }
  }
}
