@import 'src/styles';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;

  .title {
    font-size: 24px;
    font-weight: 600;
    color: $gray-8;
    margin: 5px 0 0;
    letter-spacing: 0px;
  }

  &__table {
    display: block;
  }
  &__pagination {
    padding-top: $spacing;
    padding-bottom: $spacing * 2;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    button {
      max-width: 100% !important;
    }
  }

  .row {
    td {
      text-align: center;
    }

    td:last-of-type {
      text-align: right;
    }

    @include mobile {
      font-size: 10px;
      margin: 0px;
    }

    &__expaned {
      td {
        background-color: $light;
      }
    }

    &__deleted {
      td {
        background-color: $light-3;
      }

      &:hover {
        td {
          background-color: darken(#d9d9d9, 2) !important;
        }
      }
    }

    .assignedTo {
      text-align: left;
    }
  }

  .expandableRow > td {
    padding: 0 !important;
    border-bottom: 1px solid #ece6e6;
  }

  th {
    padding: 8px 16px;
  }

  .col {
    @include mobile {
      font-size: 12px;
      font-weight: 600;
    }
  }

  button {
    max-width: 40%;
    min-width: fit-content;
  }

  .buttons:not(:disabled) {
    background: $red-pink;
    border-color: $red-pink;
  }
}

.modal {
  button[aria-label='Close'] {
    display: none;
  }
}
