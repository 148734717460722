@import 'src/styles';

.login {
  display: flex;
  align-items: center;
  width: 455px;
  flex-direction: column;

  @include tablet {
    width: 80%;
    &__form {
      margin-top: 0px;
    }
  }
  @include mobile {
    width: 100%;
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;

    &_input {
      width: 100%;
      color: $light-2;
      border-color: $gray-5;
      row-gap: 35px;
    }
    .ant-input {
      color: $gray-3;
      font-size: 20px;
    }
    &_input.mail:hover {
      border: 1px solid $primary !important;
    }
    &_input.password:hover {
      border: 1px solid $primary !important;
    }
    &_connexion {
      width: 100%;
      background-color: $primary;
      border: none;
      font-size: 16px;
      height: 40px;
    }
    &_connexion:hover {
      background-color: $primary;
      color: white;
    }

    .button {
      font-size: 15px;

      background: var(--primary-gradient-bg-color) !important;
      border-radius: 20px !important;
      color: white !important;
      border: 1px solid var(--button-border-color) !important;

      @include theme($theme-izi) {
        background: transparent linear-gradient(84deg, #13810a 0%, #51b748 50%, #84d157 100%) 0% 0% no-repeat
          padding-box;
      }

      @include theme($theme-perret) {
        background: var(--primary-gradient-bg-color);
      }

      border-radius: 25px;
      border: none;
      letter-spacing: 2.16px;
      text-transform: uppercase;
      height: 50px;
    }

    &_forgotPassLabel {
      margin: 5px 0 10px auto;

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
      .btn {
        cursor: pointer;
        letter-spacing: 0px;
        margin: 0px;
        padding: 0px;
        margin-left: 3px;
      }
    }

    > div {
      span {
        border-color: $gray-9;

        input {
          &::placeholder {
            color: $gray-2;
          }
        }

        span {
          color: $gray-2;
        }
      }
    }
  }
}
