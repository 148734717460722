@import 'src/styles';

.title {
  margin-bottom: 12px;
}

.form {
  width: 310px;
  @include mobile {
    width: 100%;
  }
}
