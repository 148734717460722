@import 'src/styles';

.content {
  min-width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .available {
    color: $green-1;
    font-size: 20px;
  }
  .unavailable {
    color: $red;
    font-size: 20px;
  }
  .text {
    font-size: 12px;
  }
}
