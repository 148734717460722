.checkbox_container {
  display: flex;
  flex-direction: column;
}

.checkbox {
  margin-left: 0 !important;
  margin-top: 8px;
}

.confirm_button {
  margin-top: 24px;
}
