@import 'src/styles';

.button {
  border-radius: 20px;
  text-align: start;

  &__image {
    color: $third;
  }
  &__text {
    padding-left: 10px;
  }
  &_empty {
    width: 40px;
  }
}
.content {
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .popoverButton {
    width: 20%;
    margin: 8px;
    @include mobile {
      width: 30%;
    }
  }
}

.modal {
  :global(.ant-image-img) {
    width: 100px;
    height: 130px;
  }

  :global(.ant-modal-body) {
    padding: 24px;
  }

  &__img {
    top: -31px;
    color: red;
  }
  &__form {
    margin-top: 28px;
  }
}

.ant-modal-body {
  border-radius: 0 !important;
}
