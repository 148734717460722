@import 'src/styles';

.container {
  max-height: 100%;
  margin-left: 24px;
  margin-top: 24px;
  &__table {
    width: 100%;
    overflow-x: auto;
    border-radius: 16px 16px 0 0;

    @include mobile {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  &__buttonContainer {
    width: 330px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    justify-content: space-between;
    gap: 16px;
  }

  &__button {
    margin: 16px 0 32px 0;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    color: $gray-8;
    margin: 5px 0 0;
    letter-spacing: 0px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.link {
  display: inline-block;
  width: initial;
}

.table_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 24px;
}
