@import 'src/styles';

.actionButton {
  padding-left: 5px;
  padding-right: 50px;
}

.infoPoint {
  margin-left: 5px;
}

.discountGroup {
  & > * {
    &:nth-child(1) {
      margin-right: 7px;
    }

    &:nth-child(2) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
    &:nth-child(3) {
      label {
        &:nth-child(1) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

.productName {
  margin-right: 15px;
}

.input {
  width: 60px;
  text-align: center;
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    &__title {
      font-weight: 600 !important;
      font-size: 14px !important;
      color: $gray-8;

      @include tablet {
        width: 40%;
      }
    }

    &__button {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }

    &__delivery {
      .icon {
        font-size: 1rem;
        min-width: 32px;
      }
    }

    &__period {
      border: 1px solid $light-3;
      border-radius: 4px;
      padding: 4px 6px;
      background-color: $light-1;
      cursor: context-menu;
    }

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .info {
        display: flex;
        flex-direction: column;
        margin: 10px auto;
      }

      &__title {
        white-space: pre-wrap;
      }
    }
  }
}

.productTitleInfo {
  margin-right: 5px;
}

.noStockAttachments {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.commentWrapper {
  textarea {
    margin-top: $spacing;
  }
}

.tagsContainer {
  display: inline-flex;
  justify-content: end;
  width: 100%;
}

.cartHeaderRightSide {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include tablet {
    flex-direction: column;
    row-gap: 15px;
  }

  &__rightContainer {
    display: flex;
    flex-direction: column;
  }
  &__actions {
    width: 100%;
    justify-content: flex-end;
    grid-area: actions;

    @media (max-width: 1200px) {
      align-items: center;
    }
  }
}
