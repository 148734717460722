@import 'src/styles';

.picker {
  display: flex;
  flex-direction: column;

  .item {
    margin-bottom: 15px;
    max-width: 330px;
    &__text {
      margin-left: 4px;
      text-align: start;
      display: block;
      &_ellipsis {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 316px;
        @include mobile {
          width: 220px;
        }
      }
    }
  }
}
