@import 'src/styles';

.space {
  width: 100%;
  display: flex;
  justify-content: center;
  .input {
    width: 310px;
    &_password {
      .item {
        border: none;
        border-radius: 2px;
        span[class='ant-input-wrapper ant-input-group'],
        span[class='ant-input-wrapper ant-input-group']:focus {
          span[class='ant-input-group-addon'] {
            background: transparent linear-gradient(180deg, $primary 0%, $secondary 100%) 0% 0% no-repeat padding-box;
            border-radius: 2px;
            color: $white;
            border: none;
            height: 40px;
            width: 40px;
          }
          span[class='ant-input-affix-wrapper ant-input-affix-wrapper-lg'] {
            border: 1px solid $light-3;
            border-left: none;
            height: 40px;
          }
        }
      }
    }
  }
}

.button {
  margin-top: calc(46px - 24px);
}
