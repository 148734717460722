@import 'src/styles';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .link {
    @include buttonLink;

    white-space: normal;
    height: auto;

    span {
      word-break: break-all;
    }

    &__disabled {
      color: var(--gray-2);
      &:hover {
        color: var(--gray-2);
      }
    }
  }
  .delete {
    color: $red;
  }
}
