@import 'src/styles';

.filters {
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;

  .form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .filtersContainer {
      display: flex;
      flex-direction: row;
      border-radius: 6px;
      justify-content: flex-start;
      flex-wrap: nowrap;
      width: 100%;

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .inputContainer {
        display: flex;
        width: 21%;
        margin-right: 5px;
        @media (max-width: 767px) {
          margin-top: 5px;
          width: 100%;
        }
        &:nth-child(2) {
          width: 14%;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
        .input {
          flex-shrink: 0;
          margin: 0px;
          width: 100%;
          height: 40px;
        }
        span {
          border-radius: 4px;
        }
      }
    }
  }
}

.filterActions {
  display: flex;
  width: 100%;
  align-items: center;
}
