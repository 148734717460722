@import 'src/styles';

.blockedPriceTip {
  color: #000000;
  margin-right: 5px;
}

.replaceOfferButton {
  margin-top: 18px;
}

.row {
  display: flex;
  width: 100%;
  padding: 32px 0px 41px 16px;
  gap: 24px;
  position: relative;

  &__noStock {
    justify-content: flex-end;
  }

  .column {
    min-width: 86px;
    display: flex;
    flex-direction: column;

    &__element {
      min-height: 50px;
      margin-bottom: 4px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      .block {
        display: block;
      }
      .notBold {
        font-weight: 400;
      }
      .centered {
        text-align: center;
        display: block;
      }
      &_group {
        .input {
          width: 40%;
          text-align: center;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          &[type='number'] {
            -moz-appearance: textfield;
          }
        }
      }
      .lastPrice {
        display: flex;
        gap: $spacing / 2;
      }
      .invalidMargin {
        color: $red;
      }
    }

    .iconButton {
      border: none;
      max-height: 20px;

      &::after,
      &:focus,
      &:active {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
      }

      svg {
        fill: #6c6c6c !important;
      }
    }
  }
  .comment {
    text-align: left;
  }
  .about {
    width: 100%;
    flex: 1;
  }
  @include mobile {
    flex-direction: column;
    padding: 12px 0 20px 0;
    &:after {
      content: '';
      background: $light-3;
      position: absolute;
      bottom: 0px;
      height: 1px;
      width: 100vw;
      transform: translate(-50%, 0%);
      left: 50%;
    }
    .column {
      justify-content: flex-end;
      flex-direction: row;
      &__element {
        width: 100%;
      }
    }
    .about {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
.marginRight {
  margin-right: 5px;
}
