@import 'src/styles';

.container {
  margin-bottom: $spacing;
  background-color: $white;

  .tab {
    font-weight: bold;
    background-color: white;

    :global(.ant-tabs-tab) {
      justify-content: center;
      font-size: 14px;
      padding-left: 40px;
      padding-right: 40px;
      margin: 0;

      &:hover {
        color: $third;
      }
    }

    :global(.ant-tabs-tab.ant-tabs-tab-disabled) {
      &:hover {
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }

    :global(.ant-tabs-ink-bar) {
      background-color: $third;
      height: 3px;
    }

    :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
      color: $third;
    }

    :global(.ant-tabs-nav) {
      margin: 0;
    }

    :global(.ant-tabs-nav::before) {
      border: none;
    }
  }

  .marginWrapper {
    padding-right: $spacing;

    .title {
      font-size: 14px;
      font-weight: 600;
      margin: 0px;
      color: $gray-3;
    }

    .clientModeToggle {
      border: none;

      &::after,
      &:focus,
      &:active {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
}
