@import 'src/styles';

.container {
  width: 100%;
  display: flex;
  align-items: center;

  .switch {
    &:global(.ant-switch-checked) {
      background-color: $green-1;
    }

    &:global(.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled)) {
      background-color: $green-1;
    }

    &:global(.ant-switch:hover:not(.ant-switch-disabled)) {
      background: rgba(0, 0, 0, 0.25);
    }
  }

  .lable {
    display: flex;
    align-items: center;
    .text {
      margin-left: $spacing / 2;
    }
  }
}
