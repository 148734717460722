@import '../styles';
@import '../styles/theme';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body {
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5715;

  @include mobile {
    overflow-y: auto;
  }
}

.ant-dropdown-trigger {
  &.triger {
    color: $blue;

    &::after {
      color: $blue;
    }

    &:hover {
      color: $blue;
    }
  }
}

.ant-collapse-header {
  background: $light !important;
  display: flex;
  align-items: center;
}

.ant-steps-finish-icon {
  color: $gray-5;
}

.ant-steps-item-icon {
  border: 1px solid $third !important;
}

.ant-steps-item-finish {
  .ant-steps-item-container {
    .ant-steps-item-icon {
      background: var(--third-4) !important;
      border-color: var(--third-4) !important;
      .ant-steps-icon {
        svg {
          fill: var(--third-button-color) !important;
        }
      }
    }
    .ant-steps-item-content {
      .ant-steps-item-title {
        font-weight: normal !important;
        color: $gray-1 !important;
        &::after {
          background-color: #f0f0f0 !important;
        }
      }
    }
  }
}

.ant-steps-item-wait {
  .ant-steps-item-title {
    color: $gray-1 !important;
    font-weight: normal;
  }
  .ant-steps-icon {
    color: $third !important;
  }
}

.ant-steps-item-process {
  .ant-steps-item-container {
    .ant-steps-item-icon {
      background-color: $third;
    }
    .ant-steps-item-title {
      font-weight: 600;
      color: $gray-8;
    }
  }
}

.ant-collapse-content {
  background: transparent !important;
  background-color: transparent !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $green;
}

.ant-checkbox-inner {
  border: 1px solid $green !important;
}

* {
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  width: 16px;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 6px;
}

.option {
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: 11px;
}
.option::first-letter {
  text-transform: uppercase;
}
