@import 'src/styles';

.container {
  background: white;
  padding: 0 0 50px;
  min-width: 700px;
  border-radius: 0 0 16px 16px;

  &__table {
    @include smallTablet {
      padding: 0 4px;
    }
  }
}

th {
  background: $primary !important;
  color: $white !important;
  font-weight: bold !important;
}
tr {
  border: 1px solid #16171b1a !important;
}

th:first-of-type {
  border-radius: 16px 0px 0px 0px !important;
  border-right: none !important;
}

th:last-of-type {
  border-radius: 0px 16px 0px 0px !important;
}

th:before {
  width: 0 !important;
}

.displayOfferButton {
  background: $secondary-2 !important;
}

.text {
  text-align: start;
}

.title {
  display: block;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.ant-table-cell {
  font-weight: bold;
}




