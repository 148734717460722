@import './theme/index';
/* Important: The map is made in the file _theme.scss. If the map was included here, the variables will duplicate for each component */

/* Define all the variables from base here */
$test-color: var(--test-color);

$primary: var(--primary);
$primary-1: var(--primary-1);
$primary-2: var(--primary-2);
$primary-3: var(--primary-3);

$secondary: var(--secondary);
$secondary-1: var(--secondary-1);
$secondary-2: var(--secondary-2);
$secondary-3: var(--secondary-3);
$secondary-4: var(--secondary-4);

$third: var(--third);
$third-4: var(--third-4);

$white: var(--white-color);

$light: var(--light);
$light-1: var(--light-1);
$light-2: var(--light-2);
$light-3: var(--light-3);
$light-4: var(--light-4);
$light-5: var(--light-5);

$gray: var(--gray);
$gray-1: var(--gray-1);
$gray-2: var(--gray-2);
$gray-3: var(--gray-3);
$gray-4: var(--gray-4);
$gray-5: var(--gray-5);
$gray-6: var(--gray-6);
$gray-7: var(--gray-7);
$gray-8: var(--gray-8);
$gray-9: var(--gray-9);

$dark: var(--dark);
$dark-1: var(--dark-1);
$dark-2: var(--dark-2);
$dark-3: var(--dark-3);
$dark-4: var(--dark-4);
$dark-5: var(--dark-5);

$red: var(--red);
$blue: var(--blue);
$lighter-blue: var(--lighter-blue);
$darker-blue: var(--darker-blue);
$blue-1: var(--blue-1);
$blue-2: var(--blue-2);
$blue-3: var(--blue-3);
$blue-4: var(--blue-4);
$orange: var(--orange);
$orange-1: var(--orange-1);
$dark-pink: var(--dark-pink);
$red-pink: var(--red-pink);

$green: var(--green);
$green-1: var(--green-1);
$green-2: var(--green-2);
$green-3: var(--green-3);
