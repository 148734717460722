@import 'src/styles';

.wrapper {
  position: relative;

  .error {
    position: absolute;
    top: -16px;
    color: $red;
    font-size: 12px;
    @include mobile {
      top: 0;
    }
  }

  .input {
    width: 282px;
    min-width: 282px;
    color: $gray-2;
    margin-right: 26.5px;
    @include mobile {
      max-width: 100%;
      min-width: 100%;
      margin-top: 17px;
    }
  }
}
