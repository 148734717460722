@import 'src/styles';

.wrapper {
  .row {
    --row-padding-x: 10px;
    --row-padding-y: 15px;

    display: grid;
    grid-template-columns: minmax(150px, 2fr) 2fr 2fr calc(40px + (var(--row-padding-x) * 2));
    grid-template-areas: 'customer address otherinfo checkmark';

    @include tablet {
      grid-template-areas:
        'customer customer otherinfo checkmark'
        'address address otherinfo checkmark';
    }
    margin-bottom: 10px;

    background: $white 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding-left: var(--row-padding-x);
    padding-right: var(--row-padding-x);
    border: 1px solid transparent;
    cursor: pointer;

    &__noLeader {
      opacity: 0.7;
      cursor: default;
      pointer-events: none;
    }

    &:hover {
      border-color: #dfdfdf;
      background-color: #fdfdfd;
    }

    &__disabled {
      pointer-events: none;
    }

    .column {
      word-break: break-all;
      padding: var(--row-padding-y) var(--row-padding-x);

      .name {
        font-weight: bold;
        color: $gray-8;
      }

      &__customer {
        grid-area: customer;
        @include tablet {
          padding-bottom: 0;
        }
      }
      &__address {
        grid-area: address;
        @include tablet {
          padding-top: 0;
        }
      }
      &__column__otherinfo {
        padding-top: 0;
        margin-top: 0;
        grid-area: otherinfo;
      }
      &__checkmark {
        grid-area: checkmark;
      }

      &__cert {
        grid-area: cert;
      }
    }

    .column__cert {
      padding-top: 0;
      grid-area: cert;
    }

    .icon {
      width: 20px;
      height: 20px;
      color: $orange;
    }
  }
}
