@import 'src/styles';

.container {
  border-top: 5px solid $light-5;
  border-bottom: 5px solid $light-5;

  .table {
    :global {
      div.ant-table {
        margin: 0 !important;

        table > thead {
          tr:first-child th:first-child,
          tr:last-child th:last-child {
            border-radius: 0 !important;
          }

          tr:first-child th:first-child {
            text-align: left;
          }

          th:first-child {
            padding-left: 16px !important;
          }

          tr {
            th {
              background-color: #ffffff !important;
              color: $gray-2 !important;
              font-weight: 800 !important;
              font-size: 0.75rem;
              text-align: center;
            }
          }
        }

        table > tbody {
          tr {
            td {
              vertical-align: top;
              text-align: center;
            }
          }
        }
      }
    }

    table > tbody tr.noStock {
      background-color: $white !important;
    }
  }
}
