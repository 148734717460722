@import 'src/styles';

.text {
  letter-spacing: 0px;
  display: inline-block;
  margin-bottom: 0px;
  font-family: 'Open Sans', sans-serif;
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $gray-4;
    text-align: center;
    &_gray8 {
      color: $gray-8;
      font-size: 16px;
      letter-spacing: 0px;
      font-weight: 600;
    }
    &_gray3 {
      color: $gray-3;
      font-size: 16px;
      letter-spacing: 0px;
      font-weight: bold;
    }
    &_white {
      color: $white;
      letter-spacing: 0px;
      font-weight: bold;
    }
  }

  &__subtitle {
    font-size: 14px;
    line-height: 20px;
    color: $gray-1;
    text-align: center;
    &_primary {
      color: $primary-1;
      font-weight: 600;
    }
    &_gray3 {
      color: $gray-3;
      font-weight: bold;
    }
    &_thin {
      color: $gray-3;
    }
    &_gray5 {
      color: $gray-5;
    }
    &_gray1 {
      color: $gray-1;
    }
    &_gray8 {
      color: $gray-8;
      font-weight: bold;
    }
    &_third {
      color: $third;
      font-weight: 600;
    }
  }

  &__secondary {
    font-size: 12px;
    line-height: 20px;
    color: $gray-5;
    &_primary {
      color: $primary-1;
      font-weight: 600;
    }
    &_gray1 {
      color: $gray-1;
    }
    &_gray3 {
      color: $gray-3;
    }
    &_gray8 {
      color: $gray-8;
      font-weight: 600;
    }
    &_thin {
      color: $gray-3;
      font-weight: 600;
    }
  }
}
