@import 'src/styles';

.textareaWrapper {
  .textarea {
    font-size: 14px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: $spacing / 2;

  .label {
    font-weight: bold;
    color: var(--gray-4);
  }

  .group {
    display: flex;
    align-items: center;
    color: darkgray;

    .commentLine {
      display: block;
      width: 100%;
    }

    &__button {
      width: 42px;
      height: 42px;
      margin-left: 5px;
      background: transparent;
      border-color: transparent;
      &__edit {
        svg {
          fill: #6c6c6c !important;
        }
      }
      &__check {
        svg {
          fill: green !important;
        }
      }
      &__check:hover {
        svg {
          fill: darkgreen !important;
        }
      }
      &__cancel {
        svg {
          fill: red !important;
        }
      }
      &__cancel:hover {
        svg {
          fill: darkred !important;
        }
      }
    }
  }
}
