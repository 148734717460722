@import 'src/styles';

.modal {
  .modal_container {
    padding: 1rem 2rem 0;
    display: flex;
    flex-direction: column;
    grid-row-gap: 1rem;

    p {
      text-align: left;
    }

    .btn {
      width: 40%;
      margin: 0 auto;
    }
  }
}

.container {
  max-height: 100%;
  margin-left: 24px;
  &__table {
    width: 100%;
    overflow-x: auto;
    border-radius: 16px 16px 0 0;

    @include mobile {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  &__cart {
    position: relative;
    width: 100%;
    height: 100%;
    .content {
      background: white;
      width: 100%;
      padding: $spacing;
      border-radius: 16px;

      &__table {
        width: 100%;
      }
      &__sum {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        .comment {
          width: 100%;
          > div:nth-child(2) {
            margin-top: 16px;
          }
          .commentWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            @include tablet {
              grid-template-columns: 1fr;
              grid-gap: $spacing;
            }

            .comment__textarea {
              textarea {
                min-height: 210px;
              }
            }
          }
          margin-right: auto;
        }
      }
      &__timeline {
        padding-top: 8px;
        margin-right: 16px;
        margin-bottom: 32px;

        :global {
          .ant-timeline-item-label {
            width: 105px;
            text-align: left;
            font-size: 11px;
          }

          .ant-timeline-item-tail,
          .ant-timeline-item-head {
            left: 105px;
          }

          .ant-timeline-item-head {
            top: -2px;
          }

          .ant-timeline-item-left .ant-timeline-item-content {
            left: 98px;
            width: 70%;
            font-size: 11px;
          }
        }
      }

      &__noTimeline {
        margin-bottom: 100px;
      }
    }

    @include mobile {
      min-height: auto;
      height: auto;
      .content {
        padding: 12px 28px;
        padding-bottom: 0px;
      }
    }
  }
}
@include smallTablet {
  .footer,
  .container {
  }
}
