@import 'src/styles';

.form {
  width: 352px;
  margin-top: 40px;
  @include mobile {
    width: 100%;
  }

  > div {
    span {
      border-color: $gray-9;
      color: $gray-9;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 6px;

    .button {
      border-radius: 25px;

      @include theme($theme-izi) {
        background: transparent linear-gradient(82deg, #13810a 0%, #51b748 50%, #84d157 100%) 0% 0% no-repeat
          padding-box;
      }

      border: none;

      span {
        letter-spacing: 2.16px;
        font-size: 15px;
        color: $white;
        text-transform: uppercase;
      }
    }
  }
}

.text_subtitle {
  padding: 0 20px;
}

.resetButton {
  max-width: 280px;
  margin-top: 18px;
}
