@import 'src/styles';

.pagination {
  text-align: right;
}

.wrapper {
  padding-bottom: 105px;
  .table {
    position: relative;

    .loading {
      z-index: 9;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-radius: 10px;
      overflow: hidden;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      &::before {
        content: '';
        position: absolute;
        background-color: white;
        opacity: 0.5;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }

    .emptyList {
      --row-padding-x: 10px;
      --row-padding-y: 15px;

      display: grid;
      grid-template-columns: 1fr;
      margin-bottom: 10px;

      background: $white 0% 0% no-repeat padding-box;
      border-radius: 10px;
      padding-left: var(--row-padding-x);
      padding-right: var(--row-padding-x);
      padding: var(--row-padding-y) var(--row-padding-x);
    }

    .emptyListMinHeight {
      min-height: 50px;
    }
  }
}
