@import 'src/styles';

.container {
  background: white;
  border-radius: 10px;
  height: 530px;
  width: 100%;
  .group {
    margin-top: 24px;
    height: calc(100% - 8px);
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 18px;
    width: 100%;
    max-width: 700px;
    .address {
      padding: 8px;
      background: $light-2;
      width: 338px;
      @include smallTablet {
        width: 100%;
      }
      transition: all 0.3s;
      cursor: default;
      &:hover {
        transition: all 0.3s;
        box-shadow: 2px 2px 5px $light-3;
      }
      label {
        width: 100%;
      }
    }
  }
}
