@import 'src/styles';

.whitelist {
  .title {
    padding-left: 25px;
    text-align: left;
    font-size: 24px;
    font-weight: 800;
    color: $gray-8;
  }

  .subtitle {
    font-size: 24px;
    color: $gray-8;
  }

  .content {
    padding: 16px 27px 29px 25px;
    background: $white;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .col {
    display: flex;
    flex-direction: column;
  }
}
