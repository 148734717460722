.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;

  .title {
    font-weight: 600;
  }
}
