@import 'src/styles';

.modal {
  @include smallScreen {
    width: calc(100% - 128px) !important;
    left: 38px;
  }

  width: calc(100% - 295px) !important;
  left: 119px;
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

  .tableMargin {
    margin-top: 25px;
  }

  .maxHeight {
    min-height: 300px;
    max-height: 600px;
    overflow-y: auto;
  }

  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }

  :global(.ant-modal-body) {
    padding: 24px;

    @include smallScreen {
      height: 900px;
    }
  }
}
