@import 'src/styles';

.wrapper {
  .groupRow {
    --row-padding-x: 10px;
    --row-padding-y: 5px;
    display: grid;
    grid-template-columns: minmax(150px, 1fr) auto;
    margin-bottom: 5px;

    background: $white 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding-left: var(--row-padding-x);
    padding-right: var(--row-padding-x);
    border: 1px solid transparent;

    border-radius: 0;
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid transparent;

    .groupColumn {
      word-break: break-all;
      padding: var(--row-padding-y) var(--row-padding-x);

      .badge {
        :global(.ant-badge-count) {
          background-color: var(--green);
        }
      }
    }
  }

  &__hasMembers {
    .groupRow {
      margin-top: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
    }
  }

  &__noMembers {
    .groupRow {
      .groupColumn {
        .name {
          color: var(--gray-3);
        }

        .badge {
          :global(.ant-badge-count) {
            background-color: var(--gray-1);
          }
        }
      }
    }

    &:last-of-type {
      .groupRow {
        margin-bottom: 10px;
      }
    }
  }

  &__groupSelected {
    .groupRow {
      background: var(--primary-gradient-bg-color);
      color: white;

      .groupColumn {
        .badge {
          :global(.ant-badge-count) {
            background-color: $white;
            color: var(--gray-3);
          }
        }
      }
    }
  }
}
