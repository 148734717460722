@import 'src/styles';

.summary {
  margin-top: 10px;
  border-radius: 16px 16px 40px 40px;
  margin-bottom: 10px;
  position: relative;
  background: transparent linear-gradient(180deg, $gray-4 0%, $dark 100%) 0% 0% no-repeat padding-box;
  .header {
    display: flex;
    align-items: center;
    padding: 20px 20px 0px 20px;
    .icon {
      color: $gray-5;
      font-size: 20px;
      margin-right: 10px;
    }
  }
  .content {
    padding: 0px 20px 20px 20px;
    margin-top: 20px;
    margin-bottom: 10px;

    .ecoBlock {
      margin-top: 12px;
    }
  }
  .button {
    height: 50px;
    border-radius: 50px;
    font-size: 15px;
    border: 5px solid #ebebeb;
    letter-spacing: 2.16px;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      border: 5px solid #ebebeb;
    }
  }

  @include mobile {
    margin-bottom: 0px;
    .button {
      border-radius: 0px;
    }
  }
}
