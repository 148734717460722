@import 'src/styles';

.main {
  display: flex;
  justify-content: space-between;
  margin: 6px;
  padding: 12px;
  min-height: 100px;

  .customerBlock {
    min-width: 300px;
    padding: 2px;
  }

  .taxesBlock {
    .rpd {
      display: flex;
      flex-direction: column;
    }
  }

  .quantityBlock {
    width: 200px;
    .slider {
      width: 90%;
    }
    .quantityInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        background-color: $third-4;
        border-color: $third-4;

        span {
          color: $third;
        }
      }

      .subtitle {
        font-size: 12px;
      }

      button:disabled {
        background-color: #f5f5f5;
        border-color: #d9d9d9;

        span {
          color: #d9d9d9;
        }
      }
    }
  }
}
