@import 'src/styles';

.content {
  .orText {
    font-weight: 700;
    font-size: 16px;
    margin-right: 34.5px;
    @include smallTablet {
      margin-right: 4px;
    }
  }

  .group {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    transition: all 0.1s;
    min-width: 190px;
    &:hover {
      transition: all 0.1s;
      opacity: 0.8;
    }
    .button {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      margin-right: 15.5px;

      background: var(--primary-gradient-bg-color) !important;
      border-radius: 20px !important;
      color: white !important;
      border: 1px solid var(--button-border-color) !important;
    }

    p {
      color: $third;
    }
  }
  @include smallTablet {
    margin-top: 8px;
    width: 100%;
  }

  @include mobile {
    margin-top: 15px;
    padding-left: 4px;
  }
}

.modal {
  .container {
    padding-top: 20px;
    padding-bottom: 50px;
    &__title {
      text-align: center;
      font-size: 38px;
      line-height: 46px;
      font-weight: 600;
      width: 100%;
      margin-bottom: 48px;
    }
  }
}
