@import 'src/styles';

.container {
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 8px;
    color: $gray-8;
  }
  .subtitle {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 19px;
    font-weight: normal;
    text-align: left;
  }

  .address_box {
    margin-bottom: 19px;

    .titleAddressBox {
      text-align: left !important;
    }

    .address_list {
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      margin-bottom: 0;

      .address {
        font-size: 14px;
        line-height: 22px;
        font-weight: normal;
        cursor: pointer;
        :hover {
          color: $secondary;
          div {
            border: $secondary 1px solid;
            background-color: $secondary;
          }
        }

        .square {
          width: 25px;
          height: 25px;
          border: black 1px solid;
          float: left;
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }
    }

    .buttonLink {
      @include buttonLink;
    }
  }

  .form {
    width: 100%;
    padding-bottom: 34px;
    display: flex;
    @include mobile {
      width: 280px;
    }
    &__input {
      margin-bottom: 15px;
      width: 85%;
    }
    button {
      font-size: 15px;
      letter-spacing: 2.16px;
      text-transform: uppercase;
      width: 15%;
      margin-left: 10px;
    }
  }
}
