@import 'src/styles';

.modal {
  div[class='ant-modal-content'] {
    background: transparent !important ;
    background-color: transparent !important ;
    padding: 0px;
  }
}
.title {
  text-align: center;
  width: 100%;
}

.imageHolder {
  div {
    position: relative;
    width: 100%;
  }
  &__img {
    width: 50px;
    height: 50px;
    position: absolute;
    transform: translate(-50%, -80px);
    border: none;
    top: -15px;
    left: 50%;
  }
}
