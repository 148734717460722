@import 'src/styles';

.container {
  .productImportContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .productImportTitle {
      margin-bottom: 37px;
    }
  }

  .button {
    height: 50px;
    font-size: 15px;
    letter-spacing: 2.16px;

    span {
      text-transform: uppercase;
    }
  }
}
