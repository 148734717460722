@import 'src/styles';

.header {
  margin-left: 24px;
  background: white;
  padding: $spacing;
  top: 0;
  position: sticky;
  z-index: 20;
  box-shadow: 0px 2px 4px $gray-6;
  border-radius: 0 0 5px 5px;

  .container {
    gap: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      text-align: start;
      font-size: 14px;
      font-weight: 400;
    }

    .content {
      &__left {
        margin-left: auto;
      }
    }
  }
  @include smallTablet {
    margin-left: 0px;
  }
}
