@import 'src/styles';

.container {
  margin: 38px 0px 25px 24px;

  &__grouped {
    margin: 8px 0px 25px 24px;
    width: calc(100% - 400px);

    .containerCertificates {
      position: fixed;
      right: 30px;
      top: 150px;
      width: 310px;
      max-height: calc(100vh - 350px);
      margin-top: 38px;
      overflow-y: auto;

      .tags {
        margin-bottom: 60px;
        .headerTags {
          margin-bottom: 10px;
          text-transform: uppercase;

          .icon {
            color: $orange-1;
            margin-right: 6px;
          }
        }

        .containerTags {
          border-radius: 5px;
          overflow: hidden;
          border-bottom: 11px solid $primary-1;
          background-color: #fff;
          padding: 14px;
          display: flex;
          justify-content: center;

          .tag {
            color: $gray-1;
            font-size: 12px;
            line-height: 20px;
          }
        }
      }

      @include smallTablet {
        margin-top: 30px;
      }

      .container {
        width: 100%;
        margin: 0;
        p {
          text-align: left;
        }
      }

      button {
        display: none;
      }
    }
  }

  @include hideScrollbar;

  @include smallTablet {
    margin-right: 24px;

    &__grouped {
      width: calc(100% - 370px);
    }
  }

  .main {
    .certificates {
      min-width: inherit;
    }

    .groupAddress {
      max-height: 420px;
      overflow-y: auto;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  .header {
    margin-bottom: 20px;
    .icon {
      color: $orange-1;
      margin-right: 6px;
    }
  }

  .content {
    .items {
      width: 100%;
      display: flex;
      flex-direction: column;

      &:last-child .group {
        margin-bottom: 105px;
      }

      &:first-child .groupName {
        margin-top: 0;
      }

      .groupName {
        font-size: 16px;
        font-weight: 600;
        margin: 6px 0px;

        @include mobile {
          text-align: center;
        }
      }

      .group {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include mobile {
          flex-direction: column;
          align-items: center;
        }

        .groups,
        .certificate {
          display: grid;
          grid-gap: 15px 25px;
          grid-template-columns: 1fr;
          width: fit-content;

          @media all and (min-width: 718px) {
            grid-template-columns: 1fr;
          }

          @media all and (min-width: 1291px) {
            grid-template-columns: 1fr 1fr;
          }

          @media all and (min-width: 1626px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }

          @media all and (min-width: 1961px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }

          @media all and (min-width: 2296px) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          }

          & > * {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include mobile {
              margin-right: 0px;
            }
          }
        }

        .certificateGroup {
          display: flex;
        }
      }
    }

    & > * {
      display: inline-block;
      height: fit-content;

      @include mobile {
        margin-right: 0px;
      }
    }

    @include mobile {
      align-items: center;
    }

    @include tablet {
      flex-direction: column;
      text-align: center;

      & > * {
        text-align: start;
      }
    }
  }

  @include mobile {
    margin: 25px 0px;
    height: 100%;
  }
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
