@import 'src/styles';

.filters {
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;

  .form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .filtersContainer {
      display: flex;
      flex-direction: row;
      border-radius: 6px;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .inputContainer {
        display: flex;
        width: 21%;
        @media (max-width: 767px) {
          margin-top: 5px;
          width: 100%;
        }
        &:nth-child(2) {
          width: 14%;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
        .input {
          flex-shrink: 0;
          margin: 0px;
          width: 100%;
          height: 40px;

          &.select {
            min-height: 40px;
            height: unset;
          }
        }
        span {
          border-radius: 4px;
        }
      }
    }
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}

.buttonContainer {
  width: 100%;
  text-align: end;
  margin-top: 10px;

  @include mobile {
    text-align: center;
  }

  .filterButton:first-child {
    color: $white;
  }

  .filterButton {
    color: $gray-8;
    margin: 2px 0px 0px 10px;
    max-width: 160px;

    @include mobile {
      margin: 2px;
    }
  }
}
