@import 'src/styles';

.row {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  &_separated {
    position: relative;
    padding-bottom: 20px;
    &:after {
      position: absolute;
      content: '';
      border-top: 3px dashed $gray-5;
      transform: scaleY(0.6);
      left: 0px;
      width: 100%;
      bottom: 0px;
      @include smallTablet {
        transform: translate(-50%, 0) scaleY(0.6) scaleX(1.4);
        left: 50%;
        width: 100vw;
      }
    }
  }
  .col {
    &_left {
      width: 100%;
      .container {
        width: 100%;
      }
    }
    &_right {
      margin-top: 88px;
      width: 450px;
      min-width: 450px;
      max-width: 450px;
    }
  }
  @include smallTablet {
    flex-direction: column;
    gap: 0px;
    .col {
      &_right {
        margin-top: 15px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}
