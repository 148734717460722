@import 'src/styles';

.card {
  margin-bottom: 10px;
  border-radius: 10px 4px 4px 4px;
  box-shadow: 0px 2px 4px $gray-6;
  .header {
    display: flex;
    align-items: center;
    border-radius: 8px 4px 0px 0px;
    height: 88px;
    border-left: 10px solid $blue-3;
    background-color: white;
    padding: 10px;
    border-bottom: 1.4px dashed $gray-5;

    @include mobile {
      height: auto;
      margin-top: 6px;
      display: flex;
      flex-direction: column;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .left {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: $gray-5;
        margin-right: 12px;
        .icon {
          svg {
            height: 38px;
            width: 34px;
            color: $third;
          }
        }
      }
      .middle {
        width: 86%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .text {
          font-weight: bold;
          text-align: left;
          @media (max-width: 1400px) {
            line-height: 26px;
          }
        }

        .textSubtitle {
          text-align: left;
        }

        @include mobile {
          display: flex;
          width: 100%;
          line-height: 0;
          font-size: 6px;
          .text {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    .right {
      margin-left: auto;

      .deliveryDate {
        display: flex;
        gap: $spacing / 2;
        align-items: center;

        @media (max-width: 1600px) {
          align-items: end;
          flex-direction: column;
        }

        &__picker {
          display: flex;
          gap: $spacing / 2;
          align-items: center;
        }
      }
    }
  }

  @include mobile {
    margin: 0px 20px 0px 25px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
