@import 'src/styles';

.loadingIcon {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

.input {
  width: 100%;
  height: 40px;
  color: $gray-2;
  margin-right: 26.5px;
  @include mobile {
    max-width: 100%;
    min-width: 100%;
    margin-top: 17px;
  }
}
