@import 'src/styles';

.block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;

  .tax {
    color: #ee5966;
    margin-right: 3px;
    padding-top: 3px;
    white-space: nowrap;
    font-weight: bold;
    display: block;
    margin-bottom: 0px;
    font-size: 10px;

    @include theme($theme-perret) {
      color: #8777d3;
    }
  }

  .price {
    color: $gray-3;
    margin-right: 3px;
    padding-top: 3px;
    white-space: nowrap;
    font-weight: bold;
    &_prev {
      text-decoration: line-through;
      color: $gray-5;
      margin-right: 4px;
    }
    &_discount {
      padding: 3px 4px;
      padding-bottom: 2px;
      border-radius: 2px;
      background: $red;
      color: white;
      font-size: 11px;
    }

    :global(span.unit) {
      font-size: 10px;
    }

    &_conditioning {
      &_label {
        font-size: 10px;
      }

      span {
        font-size: 10px;
      }

      span:last-of-type > span {
        font-size: 8px;
      }
    }
  }
  &_end {
    justify-content: flex-end;
  }
}
