@import 'src/styles';

.price {
  color: $white;
}

.resultsBlock {
  margin-top: 6px;
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(197, 197, 197, 0.212);
  width: 100%;
  height: 100%;
  z-index: 300;
}

.summary {
  margin-top: 110px;
}
