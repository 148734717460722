@import 'src/styles';

.button {
  @include theme($theme-perret) {
    background: var(--primary-gradient-bg-color) !important;
  }
  &_primary {
    :global {
      button.ant-btn {
        color: white;
        height: 40px;

        &:first-of-type {
          transition: none;
          background: transparent linear-gradient(45deg, #121f54 0%, #516599 50%, #516599 100%) 0% 0% no-repeat
            padding-box;
          border-top-left-radius: 40px !important;
          border-bottom-left-radius: 40px !important;
          border-color: $gray-8;
          border-right-color: $blue-4 !important;
        }

        &:last-of-type {
          transition: none;
          background: $blue-3;
          border-top-right-radius: 40px !important;
          border-bottom-right-radius: 40px !important;
          border-color: $gray-8;
          border-left-color: $blue-4 !important;
        }

        &:hover {
          background: var(--primary-gradient-bg-color);
          border-color: var(--button-border-color);
        }

        &:active,
        &:visited,
        &:hover,
        &:focus {
          outline: none;
        }

        &[ant-click-animating-without-extra-node='true']::after {
          display: none;
        }
      }
    }

    &.loading {
      cursor: default;
      pointer-events: none;
      opacity: 0.65;
      transition: opacity 0.2s;
      transition-property: opacity;
      transition-duration: 0.2s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }
  }
}

.loadingSpin {
  display: inline-flex;
  padding-right: 8px;
  margin-top: -3px;
}

.loadingOutlined {
  color: white;
  font-size: 15px;
  width: 14px;
}
