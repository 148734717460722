@mixin hideScrollbar {
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

// TODO: Depracted - rename isTheme and use that
@mixin theme($name) {
  &__theme_#{$name} {
    @content;
  }
}

// This have different propose than theme
@mixin isTheme($name) {
  :global(.theme-#{$name}) & {
    @content;
  }
}

@mixin buttonLink {
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  font-size: 14px;
  padding: 0;
  margin: 0;
}
