@import 'src/styles';

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @include tablet {
    grid-template-columns: 1fr;
    grid-gap: $spacing;
  }

  .comment__textarea {
    textarea {
      min-height: 210px;
    }
  }
}
