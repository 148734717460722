@import 'src/styles';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0, -20%);
  gap: 130px;

  .content {
    width: 440px;
    &__title {
      font-size: 38px;
      line-height: 46px;
      text-align: start;
      margin-bottom: 16px;
    }
    &__subtitle {
      font-size: 14px;
      line-height: 22px;
      text-align: start;
      margin-bottom: 20px;
    }
    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      .button {
        border-color: $blue-3;
        font-size: 15px;
        letter-spacing: 2.16px;
        text-transform: uppercase;
        height: 50px;
        border-radius: 25px;

        &_dashed {
          background: white;
          color: $blue-3;
        }
      }
    }
  }
  .image {
    width: 266px;
    height: 256px;
  }

  @include smallTablet {
    transform: translate(0, 0%);
    flex-direction: column-reverse;
    gap: 0px;
  }
  @include mobile {
    height: 100%;
    padding: 12px 0;
    .content {
      width: 100%;
      padding: 0 25px;
      &__title {
        margin-bottom: 5px;
        text-align: center;
      }
      &__buttons {
        flex-direction: column;
      }
    }
  }
}
