@import 'src/styles';

.product {
  display: flex;
  background-color: $light;
  margin-bottom: 2px;
  flex-direction: row-reverse;
  .delivery {
    margin: 14px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    width: 60%;
    font-weight: 600;

    p {
      font-weight: 600;
    }

    .deliveryOverride {
      text-decoration: line-through;
    }
  }

  .info {
    width: 100%;
    min-height: 88px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: $spacing;
    .unitPrice {
      color: $gray-1;
      display: inline;
      font-weight: 400;
    }
    .truckNbr {
      border: 1px solid rgba(37, 161, 6, 0.616);
      background-color: rgba(63, 248, 16, 0.089);
      border-radius: 4px;
      margin-top: 6px;
      padding: 0px 3px;
      font-size: 13px;
    }

    .total {
      margin-top: 12px;
    }

    .totalPrice {
      color: $orange;
      display: inline;
      font-weight: 600;
    }
    .totalPrice::after {
      content: ' HT';
      position: relative;
      top: -5px;
    }
    .field {
      display: flex;
      grid-column-gap: 3px;
      align-items: center;
      letter-spacing: 0.4px;
    }
  }
}
