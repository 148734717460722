@import 'src/styles';

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  .error {
    position: absolute;
    top: -18px;
    color: $red;
    font-size: 12px;
  }
  .loadingIcon {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .input {
    min-width: 282px;
    color: $gray-2;
    @include mobile {
      width: 100%;
      min-width: 100%;
      margin-top: 17px;
    }
  }
}
