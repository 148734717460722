@import 'src/styles';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0, -20%);
  gap: 130px;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 440px;

    .titleWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $spacing;

      .checkmarkImage {
        width: 80px;
        height: 100%;
      }

      .title {
        text-align: center;
        font-size: 38px;
        line-height: 46px;
        text-align: start;
        margin-bottom: $spacing * 1.5;
        text-transform: uppercase;
      }
    }

    &__subtitle {
      font-size: 14px;
      line-height: 22px;
      text-align: start;
      text-align: center;
      margin-bottom: $spacing * 2;
    }

    .warningWrapper {
      display: flex;
      gap: $spacing / 2;
      margin-bottom: $spacing * 3;
      justify-content: center;
      align-items: center;

      .warning {
        font-size: 12px;
        font-style: italic;
        line-height: 18px;
        font-weight: normal;
        text-align: left;
        max-width: 240px;
      }

      .image {
        width: 100%;
        height: 31px;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 80px;
      .button {
        padding-left: $spacing * 3;
        padding-right: $spacing * 3;
        width: auto;
        font-size: 15px;
        letter-spacing: 2.16px;
        text-transform: uppercase;
        height: 50px;
        border-radius: 7px;
        border: 0;

        &_dashed {
          background: white;
          color: $blue-3;
        }
      }
    }
  }

  @include smallTablet {
    transform: translate(0, 0%);
    flex-direction: column-reverse;
    gap: 0px;
  }
  @include mobile {
    height: 100%;
    padding: 12px 0;
    .content {
      width: 100%;
      padding: 0 25px;
      &__title {
        margin-bottom: 5px;
        text-align: center;
      }
      &__buttons {
        flex-direction: column;
      }
    }
  }
}
