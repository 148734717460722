@import 'src/styles';

.unstyledList {
  list-style-type: none;
  padding: 0;
}
.uploadMoreContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cartAttachmentListContainre {
  max-width: 350px;
}
