@import 'src/styles';

.header {
  background: white;
  height: auto;

  :global(.ant-page-header-content) {
    padding: 0;

    :global(.ant-image-img) {
      max-height: 160px;
    }
  }
}
