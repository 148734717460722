@import 'src/styles';

.groupName {
  cursor: move;
  margin-bottom: 10px;
  font-size: 15px;
  border-bottom: 1px dashed $light-2;
  display: block;
  text-align: left;
  font-weight: bold;
  padding-bottom: 10px;
}
