@import 'src/styles';

.wrapper {
  .button {
    color: var(--third);

    &:disabled {
      color: rgba(0, 0, 0, 0.25);
    }

    span {
      font-size: 1rem;
    }
  }
}
