@import 'src/styles';

.dragHandlerWrapper {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: $spacing / 2;
}

.icon {
  color: $third !important;
}

.panel {
  border-bottom: 0px !important;
  padding-bottom: 25px;
  background: white;
}

.quantityInfo {
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  .quantityValue {
    min-width: 70px;
    text-align: center;
  }
}
.groupTruck {
  border: 1px solid rgba(14, 14, 14, 0.267);
  border-radius: 4px;
  margin-bottom: 4px;
}

.trucHeader {
  border: 1px solid rgba(13, 199, 22, 0.171);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .truckRemoveEnable {
    border: 1px solid rgb(199, 13, 13);
    border-radius: 4px;
    height: 26px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    background-color: rgba(228, 1, 1, 0.123);
    color: black;
  }

  .truckRemoveDisable {
    border-radius: 4px;
    height: 26px;
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .truckNumber {
    border: 1px solid rgb(13, 199, 22);
    border-radius: 4px;
    background-color: rgba(153, 205, 50, 0.123);
    width: 100px;
    text-align: center;
    margin: 10px 0px 10px 20px;
  }
}
