@import 'src/styles';

.customerHeader {
  position: sticky;
  top: 0;
  z-index: 79;
  background: white;
  margin-left: 24px;
  padding: $spacing;
  border-radius: 5px;
  box-shadow: 0px 2px 4px $gray-6;

  .title {
    font-size: 24px;
    font-weight: 600;
    color: $gray-8;
    margin: 5px 0 0;
    letter-spacing: 0px;
  }

  &__infoText {
    text-align: left;
    color: $gray-8 !important;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
  }
  &__utility {
    margin-top: 8px;
    display: flex;
    padding: 6px 0px;
    flex-wrap: nowrap;
  }
  @include smallTablet {
    margin-left: 0px;
    &__utility {
      margin-top: 0px;
      flex-wrap: wrap;
    }
  }
  @include mobile {
    &__infoText {
      margin-bottom: 16px;
    }
    &__utility {
      flex-direction: column;
    }
  }
}
