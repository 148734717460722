@import 'src/styles';

.container {
  padding-top: 30px;
  margin-left: 24px;
  display: flex;
  .left {
    display: inline-block;
    margin-right: 24px;
    padding-bottom: 90px;
    width: calc(100% - 424px);
    .pngIcon {
      height: 34px;
      width: 30px;
    }
  }
  .right {
    width: 400px;
    overflow-y: auto;
    min-height: 700px;
    padding-bottom: 100px;
  }

  @include smallTablet {
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    .left {
      width: 100%;
      padding-bottom: 0px;
    }
    .right {
      display: flex;
      flex-direction: column;
      width: auto;
      padding-bottom: 100px;
    }
  }
  @include mobile {
    margin: 0px;
    padding-top: 25px;
    .right {
      padding-bottom: 0px;
    }
  }
  .button {
    position: sticky;
    height: 50px;
    border-radius: 50px;
    font-size: 15px;
    letter-spacing: 2.16px;
    text-transform: uppercase;
    background: var(--primary);
    color: white;
    border: 5px solid #f0f2f5;
  }
}

.comment {
  background: white;
  border-radius: 16px;
  margin-top: $spacing;
  padding: $spacing;
}

.sendQuoteContainer {
  height: 90px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 40px 40px 16px 16px;
}

.checkbox {
  margin: 10px auto;
  :global(.ant-checkbox-inner),
  :global(.ant-checkbox-checked) {
    border-color: $blue-3;
    background: white;
  }
  :global(.ant-checkbox-inner::after) {
    border-color: $blue-3;
  }
}
