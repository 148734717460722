@import 'src/styles';
.name {
  font-weight: bold;
  color: $gray-8;
}

.infoBullet {
  margin-left: 5px;
}

.column {
  min-width: 240px;
  word-break: break-word;
  padding: 15px 0;
  display: flex;
  flex-direction: column;

  &__customer {
    grid-area: customer;
  }

  &__address {
    grid-area: address;

    @include tablet {
      display: none;
    }
  }

  &__userInfo {
    grid-area: userInfo;

    @include tablet {
      display: none;
    }
  }

  &__certificates {
    grid-area: certificates;

    .certificate {
      display: flex;
      flex-direction: column;
    }
  }

  &__checkmark {
    grid-area: checkmark;

    padding: 0;
    align-self: center;

    .button {
      border-radius: 50% !important;
      width: 40px;
      min-width: 40px !important;
      height: 40px;
      min-height: 40px !important;

      @include isTheme($theme-perret) {
        background: transparent;
      }

      .icon {
        color: var(--customer-card-select-button-icon-color);
      }

      &_edit {
        margin-bottom: 15px;
        background: transparent;
        border: 1px solid $light-3;

        .icon {
          color: $gray-4;
        }
      }
    }

    &__simpleCustomer {
      .button {
        opacity: 0.5;
        pointer-events: none;
        background-color: #f0f2f5;
        border: 1px solid red;
        .icon {
          color: red;
        }
      }
    }
  }

  .name {
    font-weight: bold;
    color: $gray-8;
  }
}
