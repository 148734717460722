@import 'src/styles';

.wrapper {
  .button {
    border-radius: 50% !important;
    width: 40px;
    min-width: 40px !important;
    height: 40px;
    min-height: 40px !important;

    @include isTheme($theme-perret) {
      background: transparent;
    }

    .icon {
      color: var(--customer-card-select-button-icon-color);
    }

    &_edit {
      margin-bottom: 15px;
      background: transparent;
      border: 1px solid $light-3;

      .icon {
        color: $gray-4;
      }
    }

    &__selected {
      @include isTheme($theme-perret) {
        background: var(--primary-gradient-bg-color);
      }
      .icon {
        color: var(--customer-card-select-button-icon-color-selected);
      }
    }
  }

  &__simpleCustomer {
    .button {
      opacity: 0.5;
      pointer-events: none;
      background-color: #f0f2f5;
      border: 1px solid red;
      .icon {
        color: red;
      }
    }
  }
}

.disabled {
  .button {
    background: var(--light-2) !important;
  }
}
