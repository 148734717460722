@import 'src/styles';

.price {
  color: #ee5966;
  margin-right: 3px;
  padding-top: 3px;
  white-space: nowrap;
  font-weight: bold;
  display: block;
  margin-bottom: 0px;

  .decimals {
    position: relative;
    top: -4px;
    font-size: 12px;
  }

  .unit {
    font-size: 10px;
  }

  @include theme($theme-perret) {
    color: #8777d3;
  }
}
