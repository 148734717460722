@import 'src/styles';

.footer {
  margin-left: 24px;
  background: white;
  z-index: 30;
  position: fixed;
  width: calc(100% - var(--navbar-width-dynamic-width) - 54px);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  border-radius: 16px 16px 0 0;

  :global(.ant-steps .ant-steps-item-icon) {
    font-size: 16px;
  }

  :global(.ant-steps .ant-steps-item-wait .ant-steps-item-icon) {
    background-color: white;
  }

  @include smallScreen {
    width: calc(100% - var(--navbar-width) + 111px);
  }

  bottom: 0;
}

.step_active {
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    opacity: 0.7;
    transition: all 0.3s;
  }
}
