@import 'src/styles';

.item {
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  color: $gray-3;
  width: 100%;
  margin: 0;

  &__input {
    border: 1px solid $gray-8;
    border-radius: 4px;
    height: 60px;

    span {
      font-size: 16px;
    }
    input::placeholder {
      font-size: 16px;
    }

    &_password {
      margin-bottom: 0;
      span[class='ant-input-wrapper ant-input-group'],
      span[class='ant-input-wrapper ant-input-group']:focus {
        span[class='ant-input-group-addon'] {
          border: none;
          border-right: 1px solid $light-3;
        }
        span[class='ant-input-affix-wrapper ant-input-affix-wrapper-lg'] {
          border: none;
        }
      }
    }
  }
}

.item:first-of-type {
  margin-bottom: 35px;
}
