@import 'src/styles';

.excludeNoStockOffers {
  background: var(--primary-gradient-bg-color) !important;
  color: white;
}

.button {
  height: 40px;
  border-radius: 2px;
}

.wrapper {
  display: flex;
  align-items: center;

  @include tablet {
    flex-direction: column;
    row-gap: $spacing;
  }

  .displayAllOffersSwitchWrapper {
    display: flex;
    gap: $spacing;
    align-items: center;
    white-space: nowrap;
    width: 100%;

    button {
      height: 40px;
      border-radius: 2px;
    }

    .displayAllOffersSwitch {
      &:global(.ant-switch-checked) {
        background: var(--primary-gradient-bg-color);
      }
    }
  }

  .inputGroup {
    display: flex;

    @include theme($theme-perret) {
      & span span {
        button {
          background: var(--primary-gradient-bg-color) !important;
          :hover,
          :visited,
          :active,
          :focus {
            background: var(--primary-gradient-bg-color) !important;
          }
        }
      }
    }

    .select {
      min-width: 190px;
    }

    .input {
      min-width: 400px;
      color: $gray-1;

      & span span {
        button {
          height: 40px;
          width: 40px;
          padding: 0;
          background: transparent linear-gradient(45deg, #121f54 0%, #516599 50%, #516599 100%) 0% 0% no-repeat
            padding-box;
          border-radius: 0px 2px 2px 0px;
          border: 1px solid var(--secondary-2);
        }
      }
      @include smallTablet {
      }
    }
  }
}
