@import 'src/styles';

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  gap: 15px;
  width: 80%;
}

.col {
  width: 20%;
}

.select {
  width: 100%;
}

.button {
  padding: 3px 15px;
  width: initial;
  height: initial;
}
