@import 'src/styles';

.container {
  display: flex;
  align-content: center;
  margin: 15px 0 5px 0;

  > div:first-of-type {
    width: 80px;
    height: 80px;

    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  > div:last-of-type {
    margin-left: 10px;
  }
}
