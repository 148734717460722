@import 'src/styles';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .supplierUnitPrice {
    &__lineThrough {
      text-decoration: line-through;
    }
  }
}

.tooltip {
  max-width: 400px;

  &__content {
    display: grid;
    grid-template-columns: max-content 3fr;
    column-gap: $spacing / 2;

    &__subtitle {
      font-weight: bold;
    }

    &__comment {
      margin-top: $spacing / 2;
      grid-column: span 2;

      &__title {
        font-weight: bold;
        margin-bottom: $spacing / 4;
      }
    }
  }
}
