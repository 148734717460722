@import 'src/styles';

.filters {
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;

  .form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .filtersContainer {
      display: flex;
      flex-direction: row;
      border-radius: 6px;
      justify-content: left;
      flex-wrap: wrap;
      width: 100%;

      @include mobile {
        flex-direction: column;
      }

      .inputContainer {
        display: flex;
        flex-direction: row;
        margin: 2px;
        width: 23%;

        &:last-child {
          width: 40%;
        }
        .input {
          margin: 0px;
          width: 100%;
          height: 40px;
        }
        span {
          border-radius: 4px;
        }
      }
    }
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}

.buttonContainer {
  margin-left: auto;
  display: flex;

  .filterButton {
    margin: 2px 0px 0px 10px;
    max-width: 160px;

    @include mobile {
      margin: 2px;
    }
  }
}
