@import 'src/styles';

.wrapper {
  display: flex;
  gap: $spacing;
  align-items: center;
  pointer-events: initial;

  .attachments {
    display: flex;
    column-gap: $spacing / 2;
    word-break: break-all;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
