.modal {
  :global(div .ant-modal-body) {
    padding: 24px;
  }

  .actions {
    margin-top: 20px;
    justify-content: center;
  }
}
