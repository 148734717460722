@import 'src/styles';

.button {
  border-radius: 50% !important;
  border: 1px solid $gray-8 !important;
  background: white !important;
  color: $gray-4 !important;
  height: 40px;
  &:hover {
    border-radius: 6px !important;
    border: 1px solid $primary-1 !important;
  }
}
