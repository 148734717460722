@import 'src/styles';

.group {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 25px;
  .button {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    font-weight: normal;

    background: var(--primary-gradient-bg-color) !important;
    color: white !important;
    border: 1px solid var(--button-border-color) !important;

    @include theme($theme-perret) {
      background: var(--primary-gradient-bg-color);
    }

    &__left {
      border-radius: 32px 0px 0px 32px;
    }
    &__right {
      border-radius: 0px 22px 22px 0px;
    }
    &_disabled {
      background: $white !important;
      border: 1px solid $light-3 !important;
      color: $gray-3 !important;
    }
  }
}
