@import 'src/styles';

.main {
  height: 100vh;
  max-width: 100vw;
  overflow-y: hidden;
  @include smallTablet {
    display: flex;
    flex-direction: column;
  }
  &__rightBlock {
    min-height: 100vh;
    padding-right: var(--main-layout-padding-right);
    overflow-y: scroll;
    overflow-x: hidden;
    @include mobile {
      min-height: calc(100vh - 95px);
    }
    @include smallTablet {
    }
  }
}
