@import 'src/styles';

.group {
  display: inline-block;
  padding: 25px 0px;
  &__item {
    font-size: 14px;
    line-height: 19px;
    color: $gray-3;
    font-weight: 500;

    & span:first-child,
    &:checked {
      & span {
        border-color: $blue-3;
        &::after {
          background-color: $blue-3;
        }
      }
    }
    &:hover span:first-child {
      & span {
        border-color: $blue-3;
        &::after {
          background-color: $blue-3;
        }
      }
    }
  }
  @include smallTablet {
    padding: 0px;
  }
}
