@import 'src/styles';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;

  .title {
    font-size: 24px;
    font-weight: 600;
    color: $gray-8;
    margin: 5px 0 0;
    letter-spacing: 0px;
  }

  &__pagination {
    padding-top: $spacing;
    padding-bottom: $spacing * 2;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    button {
      max-width: 100% !important;
    }
  }

  .row {
    @include mobile {
      font-size: 10px;
      margin: 0px;
    }
  }

  .expandableRow > td {
    padding: 0;
  }

  .col {
    @include mobile {
      font-size: 12px;
      font-weight: 600;
    }
  }
}

th {
  padding: 8px 16px !important;
}

.detailsButton {
  width: 58px !important;
}
