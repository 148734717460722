@import 'src/styles';

.layout {
  height: 100vh;
  @include mobile {
    height: 100%;
    background: $light-5;
  }
}

body {
  @include mobile {
    background: $light-5;
  }
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0, -20%);
  gap: 130px;

  @include smallTablet {
    transform: translate(0, 0%);
    flex-direction: column-reverse;
    gap: 0px;
  }

  @include mobile {
    height: 100%;
    padding: 12px 0;
  }
}
