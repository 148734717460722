@import 'src/styles';

.container {
  .content {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__logo {
      position: relative;
      .img {
        width: 127px;
        height: 129px;
        position: absolute;
        transform: translate(-50%, -100px);
        top: 0px;
      }
    }
    &__title {
      margin-top: 58px;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: $gray-4;
    }
    &__picker {
      margin-top: 0px;
      scrollbar-width: 0;
      max-height: 264px;
      width: 84%;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    @include mobile {
      &__logo {
        .img {
          top: 68px;
        }
      }
      &__title {
        margin-top: 118px;
      }
    }
  }
  .validate {
    padding: 25px;
    max-height: 375px;
    overflow-y: auto;
    .buttons {
      &__add {
        margin-bottom: 15px;
        letter-spacing: 2.16px;
        text-transform: uppercase;
      }

      &__save {
        letter-spacing: 2.16px;
        text-transform: uppercase;
      }
    }
  }
  .anticon svg path {
    fill: red;
  }
}
