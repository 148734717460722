@import 'src/styles';

.button {
  background: transparent;
  border-radius: 50%;
  border: 1px solid $light-3;
  color: $gray-4;
  &:hover,
  &:visited,
  &:focus {
    color: $gray-4;
    background: transparent;
    border: 1px solid $gray-6;
  }
  &:active {
    color: $gray-4;
    background: transparent;
  }
}
