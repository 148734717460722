@import '../../../styles/index';

.wrapper {
  --active-circle-size: 100px;
  --active-circle-bg-color: var(--primary);
  --active-circle-outline-size: #{$spacing * 1.5};
  --background-inherit: var(--light-5);

  .listWrapper {
    display: flex;
    margin-top: #{$spacing * 1.5};
    justify-content: space-around;
  }

  .item {
    display: grid;
    width: 100%;
    grid-template-rows: 1fr auto;

    .label {
      margin: 0 auto;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      text-align: center;
      font-weight: 600;
      color: $white;
      min-height: 26px;
      width: calc(100% - 2 * #{$spacing});
      padding: 9px $spacing;
      font-size: 12px;
      cursor: context-menu;

      &__pending {
        background-color: $light-2;
        color: $dark;
      }

      &__draft {
        background-color: $light-3;
      }

      &__converted {
        background-color: $green-1;
      }

      &__refused {
        background-color: $red-pink;
      }

      &__in_progress {
        background-color: $lighter-blue;
      }

      &__sent_to_customer {
        background-color: $dark-1;
      }

      .btn {
        margin-bottom: 2px;
      }
    }

    .countWrapper {
      position: relative;

      .line {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;

        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          transform: translateY(calc(-50% + 1px));
          z-index: 1;
          border-bottom: 2px solid black;
        }
      }

      .count {
        overflow: hidden;
        z-index: 9;
        font-size: 18px;
        font-weight: bold;
        background-color: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        position: relative;
        height: var(--active-circle-size);
        padding-left: $spacing;
        padding-right: $spacing;

        &.active {
          width: var(--active-circle-size);
          height: var(--active-circle-size);
          background-color: var(--active-circle-bg-color);
          border: var(--active-circle-outline-size) solid var(--background-inherit);
          color: white;
          border-radius: 50%;
        }
      }

      .countNumber {
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;

        padding: var(--active-circle-outline-size);

        &.active {
          background: transparent;
        }
        background: var(--background-inherit);

        .button {
          border: none;
        }
      }
    }

    &:first-of-type {
      .countWrapper {
        .line {
          &::after {
            left: 50%;
          }
        }
      }
    }

    &:last-of-type {
      .countWrapper {
        .line {
          &::after {
            right: 50%;
          }
        }
      }
    }
  }
}
