@import 'src/styles';

.header {
  background: white;
  margin-left: 24px;
  box-shadow: 0px 2px 4px $gray-6;
  top: 0;
  position: sticky;
  padding: $spacing;
  z-index: 20;
  border-radius: 0 0 5px 5px;

  .content {
    align-items: center;
    display: flex;
    justify-content: space-between;

    p {
      font-weight: 400;
    }
  }
  .content > *:last-child {
    &__left {
      margin-left: auto;
    }
  }

  @include smallTablet {
    margin-left: 0px;
    .content > *:first-child {
      justify-content: flex-start;
      text-align: left;
      width: 100%;
    }
  }
}
