@import 'src/styles';
$imageWidth: 336px;
$imageHeight: 339px;

.loginPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url('../../assets/images/bg.jpg') no-repeat center center fixed;
  background-size: cover;

  @include theme($theme-perret) {
    background: url('../../assets/images/perret/GroupePerretBg.jpg') no-repeat center center fixed;
    justify-content: normal;
    background-size: cover;
  }

  .mainContent {
    min-width: calc(100% - 40vw);
    display: flex;
    justify-content: space-evenly;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background: #000000 0% 0% no-repeat padding-box;
      opacity: 0.5;
      border-radius: 16px;
      transform: translate(-50%, -50%);
      width: 63vw;
      height: 55vh;
      min-height: 540px;
    }

    @include theme($theme-perret) {
      &::before {
        width: calc(100% - 60vw);
        transform: none;
        top: auto;
        left: auto;
        min-height: 570px;
        height: auto;
      }
    }

    &__leftContent {
      z-index: 10;
      min-width: calc(100vw - 75vw);

      @include theme($theme-perret) {
        display: none;
      }

      &_background {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        height: 100%;

        .logo {
          width: $imageWidth;
          height: $imageHeight;
          margin: 0 auto;
        }

        @include theme($theme-agriconomie) {
          :global(.ant-image) {
            max-width: 326px;
            margin: 0 auto;
            .logo {
              width: 100%;
              height: auto;
              margin: 0 auto;
            }
          }
        }
        @include theme($theme-perret) {
          :global(.ant-image) {
            max-width: 280px;
            margin: 0 auto;
            .logo {
              width: 100%;
              height: auto;
              margin: 0 auto;
            }
          }
        }
      }

      &_bienvenueLabel {
        position: relative;
        bottom: -3%;
        color: white;
        text-align: center;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 20px;
        max-width: 300px;
        line-break: auto;
        margin: 0 auto;
      }

      .form {
        min-width: 310px;
        width: 100%;
        max-width: 400px;
      }
    }
    &__rightContent {
      z-index: 10;
      width: 30vw;
      min-height: 570px;
      height: inherit;
      background: $white;
      border-radius: 16px;
      padding: 27px;
      row-gap: 50px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > div {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;

        .logo {
          display: flex;
          justify-content: center;
          object-fit: contain;
          object-position: center;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
      }
      .languageSelector {
        width: auto;
        height: auto;
        align-self: flex-end;
        border: none;
      }
    }

    @include tablet {
      position: relative;

      @include isTheme($theme-perret) {
        width: 100%;
      }

      &::before {
        width: 76vw;
        height: 80vh;

        @include isTheme($theme-perret) {
          height: auto;
          transform: translateY(-50%);
        }
      }

      &__leftContent {
        position: absolute;
        bottom: -12%;
        left: 50%;
        transform: translate(-50%, -45%);
        min-width: 50vw;
        padding: 0 12px;
        z-index: 100;
        background: transparent;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &_background {
          &:after {
            background: transparent;
          }
          &:before {
            top: 84%;
            background: linear-gradient(160deg, $light-1 0%, $light-1 50%, $primary 50%, $secondary-2 100%);
          }
        }
      }

      &__rightContent {
        position: absolute;
        top: -25%;
        left: 50%;
        row-gap: 0px;
        transform: translate(-50%, -25%);

        @include isTheme($theme-perret) {
          transform: translate(-50%, -50%);
        }

        min-width: 50vw;
        max-height: 63vh;

        .logo {
          height: 74px;
          width: 145px;
          margin-top: 61px;
          margin-bottom: 38px;
        }
      }
    }

    @include mobile {
      &__leftContent {
        padding-bottom: 0px;
        justify-content: flex-start;
      }
      .logo {
        width: 90px;
      }
    }
  }
}
