.modal {
  :global(.ant-modal-body) {
    padding: 24px;
  }

  .container {
    height: 200px;

    .title {
      margin-bottom: 75px;
    }

    .actions {
      margin-top: 10px;
    }
  }
}
