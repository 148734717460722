@import 'src/styles';

.header {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 25px;
  padding-right: 40px;
  background: $light-5;
  .icon {
    font-size: 24px;
    color: $gray-1;
    margin-right: 11.5px;
  }

  div:last-child {
    margin-left: auto;
  }

  .status {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    text-align: center;
    font-weight: 600;
    color: $white;
    min-height: 26px;
    padding: 2px;
    font-size: 12px;
    cursor: context-menu;
    position: relative;
    top: 12%;
  }

  .pending {
    background-color: $light-2;
    color: $dark;
  }

  .draft {
    background-color: $light-3;
  }

  .converted {
    background-color: $green-1;
    color: $white;
  }

  .refused {
    background-color: $red-pink;
    color: $white;
  }

  .in_progress {
    background-color: $lighter-blue;
    color: $white;
  }

  .sent_to_customer {
    background-color: $dark-1;
    color: $white;
  }

  @include smallTablet {
    top: 140px;
  }
  @include mobile {
    top: 180px;
    padding: 25px 12px;
  }
}
