@mixin mobile() {
  @media (max-width: 480px) {
    @content;
  }
}
@mixin notMobile() {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin smallTablet() {
  @media (max-width: 980px) {
    @content;
  }
}

@mixin notSmallTablet() {
  @media (min-width: 980px) {
    @content;
  }
}

@mixin tablet() {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin notTablet() {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin wideScreen() {
  @media (max-width: 1300px) {
    @content;
  }
}

@mixin smallScreen() {
  @media (max-width: 1336px) {
    @content;
  }
}
