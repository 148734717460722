@import 'src/styles';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;

  .title {
    font-size: 24px;
    font-weight: 600;
    color: $gray-8;
    margin: 5px 0 0;
    letter-spacing: 0px;
  }

  &__pagination {
    padding-top: $spacing;
    padding-bottom: $spacing * 2;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    button {
      max-width: 100% !important;
    }
  }

  .row {
    @include mobile {
      font-size: 10px;
      margin: 0px;
    }
  }

  .col {
    @include mobile {
      font-size: 12px;
      font-weight: 600;
    }
  }

  th {
    padding: 8px 16px !important;
  }

  .expandableRow > td {
    padding: 16px 0;
  }

  .detailsContainer {
    .table {
      :global {
        div.ant-table {
          margin: 0 !important;

          table > thead {
            tr {
              th {
                background-color: #ffffff !important;
                color: $gray-2 !important;
                font-weight: 800 !important;
                font-size: 0.75rem;
                text-align: center;
              }
            }
          }

          table > tbody {
            tr {
              td {
                vertical-align: top;
                text-align: center;
              }
            }
          }
        }
      }

      table > tbody tr.noStock {
        background-color: $white !important;
      }
    }
  }
}

.dropdown {
  width: 58px;
}
