@import 'src/styles';

.content {
  width: 310px;
  margin-bottom: 100px;

  .taxes {
    width: 100%;
  }

  .tooltiptext {
    margin-top: 8px;
    padding: 0px 8px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button {
    margin-top: 18px;
    height: 50px;
    font-size: 15px;
    letter-spacing: 2.16px;

    span {
      text-transform: uppercase;
    }
  }

  @include smallTablet {
    padding-bottom: 60px;
  }

  @include mobile {
    width: 100%;
    position: relative;
    margin-bottom: 75px;

    .button {
      position: absolute;
      bottom: -101px;
      border-radius: 0px;
      width: 100vw;
      transform: translate(-50%, 0%);
      left: 50%;
      height: 75px;
    }
  }
}
