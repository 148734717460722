.content {
  width: 100%;
  .form {
    margin-top: 6px;
    .actions {
      padding-top: 10%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button {
        width: 30%;
      }
      .cancelButton {
        cursor: pointer;
      }
    }
  }
}
