@import 'src/styles';

.button {
  border-radius: 50%;
  border: 1px solid $light-3;
  background: white;
  color: $gray-4;
  width: 40px;
  padding: 0;

  &:hover {
    border-radius: 6px;
    border: 1px solid $primary-1;
  }
}
