@import 'src/styles';

.text {
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: $gray-1;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  margin-bottom: 1px;
  &__label {
    max-width: 68%;
    overflow: hidden;
  }
  &__value {
    font-weight: bold;
  }
  &_large {
    font-size: 14px;
    line-height: 19px;
    color: $gray-4;
    .text__value {
      font-size: 16px;
      line-height: 21px;
    }
  }
}
