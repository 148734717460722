@import 'src/styles';

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px 0px;
  padding-right: 8px;
  @include smallTablet {
    padding: 0px;
    padding-bottom: 16px;
  }
  .field {
    margin-top: 3px;
    text-align: left;
    font-size: 12px;

    &__name {
      font-size: 14px;
      text-align: left;
    }

    &__delivery {
      margin-top: 3px;
      font-weight: 400;
    }
  }
  .total {
    margin-top: 12px;
    font-weight: 600;
  }
  .unitPrice {
    color: $gray-1;
    display: inline;
    font-weight: 400;
  }
  .totalPrice {
    color: $orange;
    display: inline;
    font-weight: 600;
  }
  .totalPrice::after {
    content: ' HT';
    position: relative;
    top: -5px;
  }
}
