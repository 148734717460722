@import 'src/styles';
.list {
  .flag {
    display: inline-block;
    border-radius: 4px;
    color: $light;
    background: $secondary;
    padding: 0px 4px;
    padding-bottom: 1px;
    text-transform: uppercase;
    margin-right: 2.5px;
    font-weight: 500;
    font-size: 10px;
  }
  .tag {
    display: inline-block;
    border-radius: 4px;
    color: $light;
    background: $blue-1;
    padding: 0px 4px;
    padding-bottom: 1px;
    text-transform: uppercase;
    margin-right: 2.5px;
    font-weight: 500;
    font-size: 10px;
  }
}
