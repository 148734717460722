@import 'src/styles';

.modal {
  :global(.ant-modal-body) {
    padding: 24px;
  }
}

.button {
  margin: 0 12px;

  background: transparent;
  &:hover,
  &:visited,
  &:active,
  &:focus {
    background: transparent;
  }
}

.buttons {
  margin-top: 20px;
}
