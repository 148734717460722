@import 'src/styles';

.container {
  .method {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    padding: 3px;
    padding-left: 6px;
    background: $light-1;
    margin-bottom: 5px;
    border-radius: 4px;
    border: 1px solid $light-3;
    color: $gray-4;
    cursor: pointer;
    transition: all 0.3s;
    &:last-child {
      margin-bottom: 0px;
    }
    &:hover {
      transition: all 0.3s;
      background: $light-2;
    }
    &__text {
      white-space: nowrap;
      margin-bottom: 1px;
    }
    &__icon {
      margin-left: 2px;
      font-size: 18px;
    }
  }
}

.methodLabel {
  margin-bottom: 2px;
  &:last-child {
    margin-bottom: 0px;
  }
}
