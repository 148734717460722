.modal {
  :global(div .ant-modal-body) {
    padding: 24px;
  }
  .wrapper {
    .title {
      margin-bottom: 1rem;
    }

    .subtitle {
      margin-bottom: 0.75rem;
    }

    .form {
      &__item {
        :global(.ant-form-item-row) {
          display: grid;
          grid-template-columns: minmax(auto, 100px) auto;

          :global(.ant-form-item-label) {
            text-align: left;
            white-space: break-spaces;
          }
        }

        &__inputNumber {
          width: 100%;
          max-width: 200px;
        }
      }

      .button {
        min-width: 160px;
      }
    }
  }
}
