@import 'src/styles';

.discountGroup {
  & > * {
    &:nth-child(1) {
      margin-right: 7px;
    }

    &:nth-child(2) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
    &:nth-child(3) {
      label {
        &:nth-child(1) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

.input {
  width: 80px;
  text-align: center;
}
