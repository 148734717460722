@import 'src/styles';

.form {
  margin: 30px 24px 0 24px;

  @include wideScreen {
    display: block;
  }

  .select {
    width: 40%;

    @include wideScreen {
      width: 100%;
    }
  }

  .button {
    width: 13%;
    margin-left: 1rem;
    font-size: 15px;
    letter-spacing: 2.16px;
    text-transform: uppercase;

    @include wideScreen {
      width: 30%;
      margin-top: 1rem;
      margin-left: 0;
    }
  }
}
