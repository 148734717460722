@import 'src/styles';

.container {
  padding-left: 24px;
  padding-bottom: 63px;
  @include smallTablet {
    padding-bottom: 43px;
  }
  @include mobile {
    padding: 0 0px;
  }
}

.comment {
  background: white;
  border-radius: 16px;
  margin-top: $spacing;
  padding: $spacing;
}
