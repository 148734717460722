@import 'src/styles';

.header {
  margin-left: 24px;
  padding: $spacing;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  top: 0;
  position: sticky;
  z-index: 20;
  background-color: #f0f2f5 !important;

  :global(.ant-page-header-content) {
    padding-top: 0;
  }

  .container {
    width: 100%;
    .title {
      margin-bottom: 15px;
      text-align: start;
      font-size: 14px;
      color: $gray-8;
    }
    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      &__left {
        margin-bottom: 12px;
      }
      @include smallTablet {
        &__search {
          width: 100%;
        }
      }
    }
  }

  //@media (max-width: 1160px) {
  //  .cartActions {
  //display: none;
  //}
  //}
}
