@import 'src/styles';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .group {
    display: flex;
    align-items: center;
    &__input {
      margin: 0 4px;
      width: 60px;
      height: 32px;
      text-align: center;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
    &__button {
      width: 30px;
      height: 30px;
    }
    &__button:not([disabled]) {
      background: var(--third-4);
      border-color: var(--third-4);
      svg {
        fill: var(--third-button-color) !important;
      }
    }
  }
  .subtitle {
    text-align: center;
    font-size: 12px;
    line-height: 10px;
    padding-top: 4px;
  }
}
