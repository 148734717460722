.ant-picker-calendar-week-number {
  font-size: 12px;
  color: #999;
  position: absolute;
  left: -30px;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
}

.ant-picker-calendar-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
