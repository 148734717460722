/* Important: Do not include this in the _index.scss file, otherwise the code will be duplicated */

:root {
  --main-layout-padding-right: 24px;

  --navbar-width: 243px;
  --navbar-collapsed-width: 78px;

  // this variable will be modified by javascript
  --navbar-width-dynamic-width: 243px;

  --test-color: red;

  @each $key, $value in $colors-theme-base {
    --#{$key}: #{$value};
  }
}

.theme-izi {
  --test-color: blue;

  @each $key, $value in $colors-theme-izi {
    --#{$key}: #{$value};
  }
}
.theme-perret {
  --test-color: #6446f4;

  @each $key, $value in $colors-theme-perret {
    --#{$key}: #{$value};
  }
}

.theme-agriconomie {
  --test-color: green;

  @each $key, $value in $colors-theme-agriconomie {
    --#{$key}: #{$value};
  }
}
