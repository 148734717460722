@import 'src/styles';

.modal {
  :global(.ant-modal-body) {
    padding: 24px;
  }

  .wrapper {
    display: grid;
    margin-top: 25px;
    gap: 20px;
    text-align: left;
  }

  .maxHeight {
    min-height: 300px;
    max-height: 600px;
    overflow-y: auto;
  }

  .switchButtons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }

  .searchGroup {
    display: grid;
    grid-template-columns: 2fr 1fr auto;

    .searchButton {
      width: 48px;
    }
  }
}
