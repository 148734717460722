@import 'src/styles';

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  color: $white;
  min-height: 26px;
  padding: 2px;
  cursor: context-menu;
  text-transform: uppercase;

  &.statusInPending {
    background-color: $light-2;
    color: $dark;
  }

  &.statusInProgress {
    background-color: var(--lighter-blue);
  }

  &.statusExpired {
    background-color: var(--blue-2);
  }

  &.statusSentToCustomer {
    background-color: var(--dark-1);
  }

  &.statusConverted {
    background-color: var(--green-1);
  }

  &.statusRefused {
    background-color: var(--red-pink);
  }
}

.statusDateLabel {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.client {
  display: flex;
  align-content: center;
  flex-direction: column;
}

.customerName {
  font-weight: 600;
  color: $gray-8;
  font-size: 16px;
}
