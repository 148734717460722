@import 'src/styles';

.dropdown {
  margin-left: auto;

  :global {
    button:first-of-type {
      max-width: 105px;
    }
  }

  :global(.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover) {
    background: var(--primary-gradient-bg-color);
    border-color: var(--button-border-color);
  }
}

.deleteSavedCart {
  color: $dark-pink;
}
